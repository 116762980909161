import React from 'react';
import { IdPs } from '../constants/providers';
import { AppConfig } from '../types/app';
import { useIntl } from 'react-intl';
import { TextContent } from '@amzn/awsui-components-react';
import logoutMessages from '../i18n/logout.messages';

const LogoutInstructions = ({
  config,
  idp,
}: {
  config: AppConfig;
  idp: IdPs;
}): JSX.Element => {
  const { formatMessage } = useIntl();

  const genericEnterpriseInstructions = (
    <TextContent>
      {formatMessage(logoutMessages.essoSignOutInstructions)}
    </TextContent>
  );

  const idpLogoutInfo: { [i in IdPs]: React.ReactNode } = {
    [IdPs.OTP]: <></>,
    [IdPs.AWSBuilderID]: (
      <>
        <ul>
          <li>
            {' '}
            <TextContent>
              {formatMessage(logoutMessages.bidSignOutInstructionsOne, {
                link: (str) => (
                  <a
                    href="https://profile.aws.amazon.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {str}
                  </a>
                ),
              })}
            </TextContent>
          </li>
          <li>
            <TextContent>
              {formatMessage(logoutMessages.bidSignOutInstructionsTwo)}
            </TextContent>
          </li>
        </ul>
        <TextContent>
          {formatMessage(logoutMessages.forMoreInformation, {
            link: (str) => (
              <a
                href="https://docs.aws.amazon.com/signin/latest/userguide/sign-out-all-aws_builder_id.html"
                target="_blank"
                rel="noreferrer"
              >
                {str}
              </a>
            ),
          })}
        </TextContent>
      </>
    ),
    [IdPs.LoginWithAmazon]: (
      <>
        <ul>
          <li>
            {' '}
            <TextContent>
              {formatMessage(logoutMessages.lwaSignOutInstructionsOne)}
            </TextContent>
          </li>
          <li>
            {' '}
            <TextContent>
              {formatMessage(logoutMessages.lwaSignOutInstructionsTwo)}
            </TextContent>
          </li>
          <li>
            {' '}
            <TextContent>
              {formatMessage(logoutMessages.lwaSignOutInstructionsThree)}
            </TextContent>
          </li>
        </ul>
        <TextContent>
          {formatMessage(logoutMessages.forMoreInformation, {
            link: (str) => (
              <a
                href="https://www.amazon.com/gp/help/customer/display.html?nodeId=GQL359FK7DM8294C"
                target="_blank"
                rel="noreferrer"
              >
                {str}
              </a>
            ),
          })}
        </TextContent>
      </>
    ),
    [IdPs.AWSSignIn]: <></>,
    [IdPs.AmazonFederate]: (
      <ul>
        <li>
          {' '}
          <TextContent>
            {formatMessage(logoutMessages.midwaySignOutInstructionsOne)}
          </TextContent>
        </li>
        <li>
          {' '}
          <TextContent>
            {formatMessage(logoutMessages.midwaySignOutInstructionsTwo, {
              link: (str) => (
                <a
                  href="https://w.amazon.com/bin/view/NextGenMidway/FAQ#How_do_I_logout_of_NextGen_Midway_so_I_can_test_login_.2F_reset_my_timeout_to_20_hours_before_starting_work_I_don.27t_want_interrupted.3F"
                  target="_blank"
                  rel="noreferrer"
                >
                  {str}
                </a>
              ),
            })}
          </TextContent>
        </li>
      </ul>
    ),
    [IdPs.AmazonSystemFederate]: <></>,
    [IdPs.ADFS]: {
      displayName: 'ADFS',
      instructions: genericEnterpriseInstructions,
    },
    [IdPs.JPMC]: {
      displayName: 'JPMC',
      instructions: genericEnterpriseInstructions,
    },
    [IdPs.ScaleTestIdp]: { displayName: 'Scale Test IDP', instructions: <></> },
    [IdPs.AWSPartnerNetwork]: (
      <>
        <ul>
          <li>
            <TextContent>
              {formatMessage(logoutMessages.apnSignOutInstructions, {
                link: (str) => (
                  <a
                    href="https://partnercentral.awspartner.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {str}
                  </a>
                ),
              })}
            </TextContent>
          </li>
        </ul>
      </>
    ),
    [IdPs.Accenture]: genericEnterpriseInstructions,
    [IdPs.AccentureUAT]: genericEnterpriseInstructions,
    [IdPs.testProvider]: <></>,
    [IdPs.BestBuy]: genericEnterpriseInstructions,
    [IdPs.Westpac]: genericEnterpriseInstructions,
    [IdPs.Sophos]: genericEnterpriseInstructions,
    [IdPs.Shell]: genericEnterpriseInstructions,
    [IdPs.Jefferies]: genericEnterpriseInstructions,
    [IdPs.Curtin]: genericEnterpriseInstructions,
    [IdPs.Principal]: genericEnterpriseInstructions,
    [IdPs.GandalfSession]: <></>,
  };

  return (
    <>
      {idpLogoutInfo[idp] ? idpLogoutInfo[idp] : genericEnterpriseInstructions}
    </>
  );
};

export default LogoutInstructions;
