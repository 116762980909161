import { useRef, useEffect } from 'react';
import { TextContent } from '@amzn/awsui-components-react';
import CardContainer from '../components/CardContainer';
import ContainerLayout from '../components/ContainerLayout';
import Footer from '../components/Footer';
import Main from '../components/Main';
import TopHeader from '../components/TopHeader';
import metricsService from '../services/metrics';
import { IdPs, requiredLogoutUrlParams } from '../constants/providers';
import { LAST_USED_PROVIDER_KEY } from '../constants/storage';
import providersService from '../services/providers';
import { localStorageAdapter } from '../services/storage';
import { AppConfig } from '../types/app';
import logoutMessages from '../i18n/logout.messages';
import { useIntl } from 'react-intl';
import LogoutInstructions from '../components/LogoutInstructions';
import { renderProviderName } from '../utils/renderProviderName';

export const Logout = ({ config }: { config: AppConfig }) => {
  const { formatMessage } = useIntl();

  const clientID = providersService.getClientId(requiredLogoutUrlParams);
  const { idp } = JSON.parse(
    localStorageAdapter.getItem(LAST_USED_PROVIDER_KEY) || '{}'
  );
  let providerDisplayName = renderProviderName(idp?.idp, formatMessage);

  const lxpName = config.clients[clientID].displayName;

  const metricsPublisher = useRef(metricsService.getPublisher('Logout'));

  useEffect(() => {
    metricsPublisher.current.publishCounterMonitor('Impressions', 1);
  }, []);
  useEffect(() => {
    if (!providerDisplayName) {
      metricsPublisher.current.publishCounterMonitor(
        'ProviderDisplayNameNotfound',
        1
      );
    }
  }, [providerDisplayName]);
  const enableCookieComponent = Boolean(
    config?.enableCookieComponent === 'true'
  );
  return (
    <>
      <TopHeader config={config} />
      <Main config={config}>
        <ContainerLayout>
          {
            <CardContainer
              header={
                <>
                  <TextContent>
                    {formatMessage(logoutMessages.signedOutLxp, {
                      lxp: lxpName,
                    })}
                  </TextContent>
                  <TextContent>
                    {formatMessage(logoutMessages.signedOutIdPAlso, {
                      lxp: lxpName,
                      idp: providerDisplayName,
                    })}
                  </TextContent>
                </>
              }
            >
              <LogoutInstructions config={config} idp={idp.idp as IdPs} />
            </CardContainer>
          }
        </ContainerLayout>
      </Main>
      <Footer enableCookieComponent={enableCookieComponent} />
    </>
  );
};
