import { AppConfig } from '../types/app';

import { getAmplifyConfig } from '../components/postAuth/ValidateAccountLink';
import {
  AuthToken,
  marshalAuthToken,
  unmarshalAuthToken,
} from '../utils/authToken';
import Auth from '@aws-amplify/auth';
import { useStateStorage } from '../contexts/StateStorageContextProvider';
import { useNonceGenerator } from '../contexts/NonceGeneratorContextProvider';
import { buildNonce } from '../services/auth';
import jwtDecode from 'jwt-decode';
import { FederatedSignInOptions } from '@aws-amplify/auth/lib-esm/types';
import logger from '../utils/logger';
const LogOutValidatedWrongAccount = ({ config }: { config: AppConfig }) => {
  const lastUsedGandalfToken = 'LAST_USED_GANDALF_TOKEN';
  const lastUsedValidationToken = 'LAST_USED_VALIDATION_TOKEN';
  const lastUsedState = 'LAST_USED_STATE';
  const lastUsedToken = 'LAST_USED_TOKEN';

  const stateStorage = useStateStorage();
  const nonceGenerator = useNonceGenerator();
  const amplifyConfig = getAmplifyConfig(config);
  Auth.configure(amplifyConfig.Auth);
  const nonce = buildNonce(nonceGenerator);
  let marshalledGandalfAuthToken = localStorage.getItem(lastUsedGandalfToken);
  const marshalledValidationAuthToken = localStorage.getItem(
    lastUsedValidationToken
  );
  const lastUsedStateValue = localStorage.getItem(lastUsedState);
  let originalAuthToken: AuthToken | null;
  let validationAuthToken: AuthToken | null;
  if (
    marshalledGandalfAuthToken &&
    marshalledValidationAuthToken &&
    lastUsedStateValue
  ) {
    originalAuthToken = unmarshalAuthToken(
      marshalledGandalfAuthToken,
      lastUsedStateValue
    );
    validationAuthToken = unmarshalAuthToken(
      marshalledValidationAuthToken,
      lastUsedStateValue
    );

    //Marshall token with new nonce for authN
    const authToken: AuthToken = {
      originalRequestURL: originalAuthToken?.originalRequestURL ?? '',
      jwtToken: originalAuthToken?.jwtToken ?? '',
      nonce,
    };

    marshalledGandalfAuthToken = marshalAuthToken(authToken);

    localStorage.setItem(lastUsedToken, marshalledGandalfAuthToken);

    if (originalAuthToken && validationAuthToken) {
      const validatedAuthTokenDecoded: any = jwtDecode(
        validationAuthToken?.jwtToken
      );

      const validateProvider =
        validatedAuthTokenDecoded['public_provider_name'];
      const baseUrl = `https://${config.gandalfDomain}/oauth2/authorize`;
      var urlParams = new URLSearchParams(window.location.search);
      const IdpURl = `${baseUrl}?${urlParams.toString()}`;
      stateStorage.setItem(nonce, IdpURl);
      //Trying Federated SignIn with Amplify
      Auth.federatedSignIn({
        provider: validateProvider,
        customState: nonce,
      } as FederatedSignInOptions).catch((error: Error) => {
        logger.debug('Failed to do federatedSignIn.', error);
      });
    }
  }

  return <></>;
};

export default LogOutValidatedWrongAccount;
