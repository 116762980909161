import {
  Button,
  Container,
  Form,
  Header,
  RadioGroup,
  SpaceBetween,
  TextContent,
} from '@amzn/awsui-components-react';
import {
  FailedAssociationEntry,
  PostAuthComponentParams,
} from '../../types/auth';
import ContainerLayout from '../ContainerLayout';
import Main from '../Main';
import { useIntl } from 'react-intl';
import accountLinkWarning from '../../i18n/accountLinkWarning.messages';
import { useEffect, useState } from 'react';
import { IdPs } from '../../constants/providers';
import { renderProviderName } from '../../utils/renderProviderName';
import { replayAuth } from '../../services/auth';
import { AppConfig } from '../../types/app';

export const CONTINUE_BUTTON_DATA_ID = 'alw_continue';
export const SELECTION_OPTIONS_DATA_ID = 'alw_selectidp';

export const AccountLinkWarningContainer = ({
  children,
  config,
}: {
  children: React.ReactNode;
  config?: AppConfig;
}) => {
  const { formatMessage } = useIntl();

  return (
    <Main config={config}>
      <ContainerLayout>
        <Container
          header={
            <Header>{formatMessage(accountLinkWarning.containerHeader)}</Header>
          }
        >
          {children}
        </Container>
      </ContainerLayout>
    </Main>
  );
};

export const AccountLinkWarningInfo = ({
  multipleMatches,
  email,
}: {
  multipleMatches: boolean;
  email: string;
}) => {
  const { formatMessage } = useIntl();

  return (
    <SpaceBetween direction="vertical" size="m">
      <TextContent>
        {formatMessage(
          multipleMatches
            ? accountLinkWarning.infoMessagePlural
            : accountLinkWarning.infoMessageSingular,
          {
            email,
            p: (str) => <p> {str} </p>,
          }
        )}
      </TextContent>
      <TextContent>
        {formatMessage(accountLinkWarning.chooseSignin)}
      </TextContent>
    </SpaceBetween>
  );
};

export const AccountLinkWarningOptionsSelector = ({
  options,
  onSelect,
  currentIdp,
}: {
  options: FailedAssociationEntry[];
  onSelect: (option: string) => void;
  currentIdp: IdPs;
}) => {
  const { formatMessage, formatDate } = useIntl();
  let [value, setValue] = useState<string>('0');

  useEffect(() => {
    options.sort((a, b) => b.LastSignedIn - a.LastSignedIn);
    onSelect(options[0].IDP);
    /* eslint-disable-next-line */
  }, []);

  return (
    <RadioGroup
      data-testid={SELECTION_OPTIONS_DATA_ID}
      value={value}
      onChange={({ detail }) => {
        setValue(detail.value);
        const detailIndex = parseInt(detail.value);
        if (detailIndex < options.length) {
          onSelect(options[detailIndex].IDP);
        } else {
          onSelect(currentIdp);
        }
      }}
      items={[
        ...options.map((option, i) => ({
          value: `${i}`,
          label: (
            <TextContent>
              {formatMessage(accountLinkWarning.accountSelectionOption, {
                provider: renderProviderName(option.IDP as IdPs, formatMessage),
                date: formatDate(option.LastSignedIn, {
                  dateStyle: 'long',
                }),
              })}
            </TextContent>
          ),
          description: formatMessage(
            accountLinkWarning.accountSelectionOptionDescription
          ),
        })),
        {
          value: `${options.length}`,
          label: formatMessage(accountLinkWarning.currentSignInMethodOption, {
            provider: renderProviderName(currentIdp, formatMessage),
          }),
          description: formatMessage(
            accountLinkWarning.currentSignInMethodOptionDescription
          ),
        },
      ]}
    />
  );
};

export const AccountLinkWarningSubmitForm = ({
  onContinue,
  selectedIdp,
  currentIdp,
}: {
  onContinue: () => void;
  selectedIdp: string;
  currentIdp: IdPs;
}) => {
  const { formatMessage } = useIntl();
  return (
    <Button
      onClick={(e) => {
        e.preventDefault();
        if (selectedIdp === currentIdp) {
          onContinue();
        } else {
          replayAuth(selectedIdp, new URLSearchParams(window.location.search));
        }
      }}
      variant="primary"
      data-testid={CONTINUE_BUTTON_DATA_ID}
    >
      {formatMessage(accountLinkWarning.continueButton)}
    </Button>
  );
};

export const AccountLinkWarning = ({
  user,
  onContinue,
  config,
}: PostAuthComponentParams) => {
  let [selectedIdp, setSelectedIdp] = useState<string>(user.providerName);

  if (
    !(config.enableIdLinkingWarningScreen === 'true') ||
    !user.failed_associate?.FailedAssociations.length ||
    !user.email
  ) {
    onContinue();
    return <></>;
  }

  return (
    <AccountLinkWarningContainer config={config}>
      <Form
        actions={
          <AccountLinkWarningSubmitForm
            onContinue={onContinue}
            selectedIdp={selectedIdp}
            currentIdp={user.providerName as IdPs}
          />
        }
      >
        <SpaceBetween direction="vertical" size="xs">
          <AccountLinkWarningInfo
            multipleMatches={
              user.failed_associate.FailedAssociations.length > 1
            }
            email={user.email}
          />
          <AccountLinkWarningOptionsSelector
            options={user.failed_associate.FailedAssociations}
            onSelect={(option) => {
              setSelectedIdp(option);
            }}
            currentIdp={user.providerName as IdPs}
          />
        </SpaceBetween>
      </Form>
    </AccountLinkWarningContainer>
  );
};
