import { useEffect, useState } from 'react';
import { urlSafeDecode } from '@aws-amplify/core';
import { useStateStorage } from '../contexts/StateStorageContextProvider';
import { IdProvider } from '../types/auth';

function useIdpFromState(): IdProvider | undefined {
  const [receivedIdp, setReceivedIdp] = useState<IdProvider | undefined>();
  const stateStorage = useStateStorage();

  useEffect(() => {
    // Amplify takes the `customState` provided to `Auth.federatedSignIn()`, encodes it using `urlSafeEncode()`, and
    // appends it to its own generated state, separated by a dash.  We reverse this process to get the original
    // `customState`.
    const stateParam = new URLSearchParams(window.location.search).get('state');
    if (!stateParam) {
      return;
    }

    let stateKey: string;
    try {
      const encodedStateKey = stateParam.split('-').splice(1).join('-');
      stateKey = urlSafeDecode(encodedStateKey);
    } catch (stateError) {
      return;
    }
    if (!stateKey) {
      return;
    }

    const state = stateStorage.getItem(stateKey);
    if (state) {
      const idp = JSON.parse(state);
      setReceivedIdp(idp);
    }
  }, [stateStorage]);

  return receivedIdp;
}

export default useIdpFromState;
