import { useEffect, useState } from 'react';
import { AppConfig } from '../types/app';
import { IdProvider } from '../types/auth';

export type MaintenanceInfo = {
  displayMaintenance: boolean;
  header: string;
  content: string;
};

const NO_MAINTENANCE: MaintenanceInfo = {
  displayMaintenance: false,
  header: '',
  content: '',
};

function useMaintenanceInfo(
  config: AppConfig,
  providers: IdProvider[]
): MaintenanceInfo {
  const [maintenanceInfo, setMaintenanceInfo] = useState(NO_MAINTENANCE);
  useEffect(() => {
    if (!config.maintenanceBanner) return;
    const { header, content, affectedIdps } = config.maintenanceBanner;
    if (header === '' || content === '') return;

    const info = {
      displayMaintenance: true,
      header,
      content,
    };

    // Check impacted identity providers, if present
    if (affectedIdps && affectedIdps !== '') {
      const affectedProviders = affectedIdps.split(',');
      const hasAffectedProvider = providers.some((p) => {
        return affectedProviders.includes(p.idp);
      });
      if (hasAffectedProvider) {
        setMaintenanceInfo(info);
      }
    } else {
      setMaintenanceInfo(info);
    }
  }, [config.maintenanceBanner, providers]);

  return maintenanceInfo;
}

export default useMaintenanceInfo;
