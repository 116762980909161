import { useEffect, useState, useCallback } from 'react';
import { APP_CLIENT_LOOKUP_PATH } from '../constants/api';

import providersService from '../services/providers';
import { AppConfig } from '../types/app';
import { IdProvider } from '../types/auth';
import { request } from '../utils/apiHelper';
import logger from '../utils/logger';

// https://tiny.amazon.com/5qqjir88
type GetIdPbyAppClientOutput = {
  identityproviders: { id: string }[];
};

export async function getIdentityProviders(
  config: AppConfig,
  clientId: string
): Promise<GetIdPbyAppClientOutput | undefined> {
  const response = await request(
    `${config.gandalfIDPEndpoint}${APP_CLIENT_LOOKUP_PATH}/${clientId}`,
    {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
      },
    },
    { metricNs: 'GetClientConfig', treat404AsOk: true }
  );
  if (response.status === 404) return;
  return response.json();
}

function useProviders(config: AppConfig) {
  const clientId = providersService.getClientId();

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<Error | undefined>();
  const [providers, setProviders] = useState<IdProvider[] | undefined>();

  const fetchProviders = useCallback(async () => {
    try {
      // Short circuit if the AppClient API is not enabled, get providers statically.
      if (config.enableAppClientApi !== 'true') {
        setProviders(providersService.getProvidersFromConfig(config, clientId));
        setIsLoading(false);
        return;
      }

      setError(undefined);
      setProviders(undefined);

      const result = await getIdentityProviders(config, clientId);

      if (!result?.identityproviders || !result?.identityproviders.length) {
        throw new Error('No providers have been enabled for the app client');
      }

      setProviders(
        result.identityproviders.map((idp) =>
          providersService.transformNameToIdProvider(
            idp.id,
            config.gandalfDomain
          )
        )
      );
      setIsLoading(false);
      return {
        isLoading,
        error,
        providers,
      };
    } catch (error: any) {
      logger.debug(error);
      setError(error);
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, config]);

  useEffect(() => {
    fetchProviders();
  }, [fetchProviders]);

  return {
    isLoading,
    error,
    providers,
  };
}

export default useProviders;
