import { SyntheticEvent, useEffect, useState } from 'react';
import ContainerLayout from '../components/ContainerLayout';
import Footer from '../components/Footer';
import Main from '../components/Main';
import TopHeader from '../components/TopHeader';
import { useIntl } from 'react-intl';
import accountlinkConsent from '../i18n/accountlinkConsent.messages';
import {
  Box,
  Button,
  Form,
  SpaceBetween,
  TextContent,
  Container,
  Header,
  Link,
} from '@amzn/awsui-components-react';

import { localStorageAdapter } from '../services/storage';
import { AppConfig } from '../types/app';
import { useNonceGenerator } from '../contexts/NonceGeneratorContextProvider';

import { buildNonce } from '../services/auth';
import { AuthToken, marshalAuthToken } from '../utils/authToken';

import jwtDecode from 'jwt-decode';
import { useLocation } from 'react-router-dom';
import { AppURL } from '../constants/urls';
import { MemoryAuthStorage } from '../authConfig/amplify';
import usePageTitle from '../hooks/usePageTitle';
import { providerMessages } from '../i18n/provider.messages';
import { IdPs } from '../constants/providers';
import { signoutUrlMap } from '../constants/signoutUrls';
import { emitAccountLinkingRUMEvents } from '../services/emitAccountLinkingRUMEvent';
import { accountlinkfaqPageDetails } from '../i18n/accountLinkFaqContent.messages';
import styles from './CardContainer.module.css';
import { AccountLinkICantButton } from './AccountLinkICantButton';
import CantValidateAccount from './CantValidateAccount';

export function getLogoutUrl(config: AppConfig) {
  const redirectUrl = `${window.location.origin}${AppURL.LogOutValidatedWrongAccount}`;
  return `https://${config.accountLinkAuthGandalfDomain}/logout?client_id=${config.accountLinkAuthClientID}&logout_uri=${redirectUrl}&response_type=code`;
}

export function getAmplifyConfigForLogOut(config: AppConfig) {
  const redirectUrl = `${window.location.origin}${AppURL.LogOutValidatedWrongAccount}`;

  return {
    Auth: {
      storage: MemoryAuthStorage,
      region: config.region,
      userPoolId: config.authUserPoolId,
      userPoolWebClientId: config.authClientId,
      oauth: {
        domain: config.gandalfDomain,
        scope: ['openid'],
        responseType: 'code',
        redirectSignOut: redirectUrl,
      },
    },
  };
}

const ValidatedWrongAccount = ({ config }: { config: AppConfig }) => {
  const { formatMessage } = useIntl();
  const [showCant, setShowCant] = useState(false);

  const nonceGenerator = useNonceGenerator();

  usePageTitle(
    formatMessage(accountlinkConsent.areYouSureYouWantSkipLinkingPageTitle)
  );

  const lastUsedGandalfToken = 'LAST_USED_GANDALF_TOKEN';
  const lastUsedValidationToken = 'LAST_USED_VALIDATION_TOKEN';
  const lastUsedState = 'LAST_USED_STATE';
  var originalAuthToken: string = '';
  var validationAuthToken: string = '';

  //Code to get the auth token from the history
  const location = useLocation();
  const hState = location.state;
  if (hState && (hState as any).originalAuthToken) {
    originalAuthToken = (hState as any).originalAuthToken;
  }

  if (hState && (hState as any).validatedAuthToken) {
    validationAuthToken = (hState as any).validatedAuthToken;
  }

  const originalAuthTokenDecoded: any = jwtDecode(originalAuthToken);
  const validatedAuthTokenDecoded: any = jwtDecode(validationAuthToken);

  const originalProviderName =
    validatedAuthTokenDecoded['public_provider_name'];

  const nonce = buildNonce(nonceGenerator);
  var urlParams = new URLSearchParams(window.location.search);
  const authToken: AuthToken = {
    originalRequestURL: urlParams.toString(),
    jwtToken: originalAuthToken ?? '',
    nonce,
  };
  const validationAuthTokenToStore: AuthToken = {
    originalRequestURL: urlParams.toString(),
    jwtToken: validationAuthToken ?? '',
    nonce,
  };

  useEffect(() => {
    emitAccountLinkingRUMEvents(
      originalAuthToken,
      'user_valideted_wrong_account_screen'
    );
    // eslint-disable-next-line
  }, []);
  function handleFormSubmit(event: SyntheticEvent): void {
    event.preventDefault();
    emitAccountLinkingRUMEvents(
      originalAuthToken,
      'user_valideted_wrong_account_confirm_existing_profile_button'
    );
    const marshaledGandalfAuthToken: string = marshalAuthToken(authToken);
    const marshaledValidationAuthToken: string = marshalAuthToken(
      validationAuthTokenToStore
    );

    localStorageAdapter.setItem(
      lastUsedGandalfToken,
      marshaledGandalfAuthToken
    );
    localStorageAdapter.setItem(
      lastUsedValidationToken,
      marshaledValidationAuthToken
    );

    localStorageAdapter.setItem(lastUsedState, nonce);

    //Call the cognito logout endpoint
    const logoutUrl = getLogoutUrl(config);
    window.location.href = logoutUrl + `&state=${nonce}`;
  }

  return (
    <>
      <TopHeader config={config} />
      <Main config={config}>
        <ContainerLayout>
          <div className={styles.heading_div}>
            <TextContent>
              <h1>{formatMessage(accountlinkConsent.header)}</h1>
            </TextContent>
          </div>
          {showCant ? (
            <CantValidateAccount
              authToken={authToken}
              config={config}
              onDismiss={() => setShowCant(false)}
            />
          ) : (
            <Container
              header={
                <>
                  <Header
                    className={styles.header}
                    variant="h2"
                    headingTagOverride="h1"
                  >
                    {formatMessage(
                      accountlinkConsent.youHaveSignedInUsingTheWrongEmailHeader
                    )}
                  </Header>
                </>
              }
            >
              <form onSubmit={handleFormSubmit} noValidate>
                <Form
                  actions={
                    <SpaceBetween direction="horizontal" size="xs">
                      <AccountLinkICantButton
                        authToken={authToken}
                        onCantClicked={() => setShowCant(true)}
                        config={config}
                      />
                      <Button
                        variant="primary"
                        formAction="submit"
                        data-testid="email-submit"
                      >
                        {formatMessage(
                          accountlinkConsent.confirmExistingProfileOwnershipInWrongPageButton,
                          {
                            currentProvider:
                              providerMessages[originalProviderName as IdPs]
                                .defaultMessage,
                          }
                        )}
                      </Button>
                    </SpaceBetween>
                  }
                >
                  <SpaceBetween direction="vertical" size="s">
                    <TextContent>
                      {originalProviderName === IdPs.OTP
                        ? formatMessage(
                            accountlinkConsent.youHaveSignedInUsingTheWrongEmailMessageOtpProvider,
                            {
                              originalUserEmail:
                                originalAuthTokenDecoded['email'],
                              validatedUserEmail:
                                validatedAuthTokenDecoded['email'],
                              currentProviderName:
                                providerMessages[originalProviderName as IdPs]
                                  .defaultMessage,
                              // These makes sure our help content can have some formatted content.
                              ol: (str) => <ol>{str}</ol>,
                              ul: (str) => <ul>{str}</ul>,
                              li: (str) => <li>{str}</li>,
                              signOutLink: (str) => (
                                <Link
                                  href={signoutUrlMap.get(
                                    originalProviderName as IdPs
                                  )}
                                  target="_blank"
                                  rel="noreferrer"
                                  external={true}
                                >
                                  {str}
                                </Link>
                              ),
                              table: (str) => <table>{str}</table>,
                              tr: (str) => <tr>{str}</tr>,
                              th: (str) => <th>{str}</th>,
                              td: (str) => <td> {str} </td>,
                              b: (str) => <b>{str}</b>,
                            }
                          )
                        : formatMessage(
                            accountlinkConsent.youHaveSignedInUsingTheWrongEmailMessage,
                            {
                              originalUserEmail:
                                originalAuthTokenDecoded['email'],
                              validatedUserEmail:
                                validatedAuthTokenDecoded['email'],
                              currentProviderName:
                                providerMessages[originalProviderName as IdPs]
                                  .defaultMessage,
                              // These makes sure our help content can have some formatted content.
                              ol: (str) => <ol>{str}</ol>,
                              ul: (str) => <ul>{str}</ul>,
                              li: (str) => <li>{str}</li>,
                              signOutLink: (str) => (
                                <Link
                                  href={signoutUrlMap.get(
                                    originalProviderName as IdPs
                                  )}
                                  target="_blank"
                                  rel="noreferrer"
                                  external={true}
                                >
                                  {str}
                                </Link>
                              ),
                              table: (str) => <table>{str}</table>,
                              tr: (str) => <tr>{str}</tr>,
                              th: (str) => <th>{str}</th>,
                              td: (str) => <td> {str} </td>,
                              b: (str) => <b>{str}</b>,
                            }
                          )}
                    </TextContent>
                    <TextContent>
                      <Box margin={{ top: 's' }} float="left">
                        <a
                          href={`${AppURL.AccountLinkFAQ}`}
                          target="_blank"
                          data-testid="account-link-help-link"
                          rel="noopener noreferrer"
                          onClick={() => {
                            emitAccountLinkingRUMEvents(
                              authToken.jwtToken,
                              'confirm_exisiting_profile_learn_more_button'
                            );
                          }}
                        >
                          {formatMessage(
                            accountlinkfaqPageDetails.learnMoreAboutProfileLinking
                          )}
                        </a>
                      </Box>
                    </TextContent>
                  </SpaceBetween>
                </Form>
              </form>
            </Container>
          )}
        </ContainerLayout>
      </Main>
      <Footer />
    </>
  );
};

export default ValidatedWrongAccount;
