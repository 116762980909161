import styles from '../index.module.css';
import { AppConfig } from '../types/app';

const getPageStyle = (config: AppConfig): string => {
  var pageStyling = styles.wrapper;
  if (config.enableCloudScapeVisualRefresh === 'true') {
    pageStyling = `${pageStyling} awsui-visual-refresh`;
  }
  return pageStyling;
};

export default getPageStyle;
