import {
  Modal,
  Box,
  SpaceBetween,
  Button,
  TextContent,
  Alert,
} from '@amzn/awsui-components-react';
import React, { useEffect, useState } from 'react';
import { GandalfUser } from '../types/auth';
import { useIntl } from 'react-intl';
import identityConsentMessages from '../i18n/identityConsent.messages';
import { AppConfig } from '../types/app';
import { queryApi } from '../hooks/useGetPublicIdpDetails';
import { useNonceGenerator } from '../contexts/NonceGeneratorContextProvider';
import { postConsent } from '../services/consent';

export const AreYouSureConsentModal = ({
  user,
  onContinue,
  onCancel,
  config,
}: {
  user: GandalfUser;
  onContinue: () => void;
  onCancel: () => void;
  config: AppConfig;
}) => {
  const [visible, setVisible] = React.useState(true);
  const [isSSO, setIsSSO] = useState<boolean | undefined>(undefined);

  const { formatMessage } = useIntl();

  const nonceGenerator = useNonceGenerator();
  const params = new URLSearchParams(window.location.search);

  useEffect(() => {
    const checkIsSSOIdp = async (user: GandalfUser, config: AppConfig) => {
      const idpDetails = await queryApi(config, user.providerName);

      setIsSSO(idpDetails?.idpType === 'esso');
    };
    checkIsSSOIdp(user, config);
    // eslint-disable-next-line
  }, []);

  const handleDeclineConsent = async () => {
    await postConsent(
      config.gandalfProfileEndpoint,
      false,
      user,
      nonceGenerator,
      params,
      config
    );

    isSSO ? onCancel() : onContinue();
  };

  if (isSSO !== undefined) {
    const modalContent = getModalContent(isSSO, formatMessage);

    return (
      <Modal
        onDismiss={() => setVisible(false)}
        visible={visible}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                variant="link"
                onClick={(e) => {
                  e.preventDefault();
                  setVisible(false);
                  onCancel();
                }}
              >
                {formatMessage(identityConsentMessages.cancelButton)}
              </Button>
              <Button variant="primary" onClick={handleDeclineConsent}>
                {isSSO
                  ? formatMessage(identityConsentMessages.signOutButton)
                  : formatMessage(
                      identityConsentMessages.continueToIndividualAccount
                    )}
              </Button>
            </SpaceBetween>
          </Box>
        }
        header={formatMessage(identityConsentMessages.areYouSure)}
      >
        {modalContent}
      </Modal>
    );
  } else {
    return <></>;
  }
};

const getModalContent = (isSSO: boolean, formatMessage: any) => {
  if (isSSO) {
    return (
      <>
        <SpaceBetween size="s">
          <TextContent>
            {formatMessage(identityConsentMessages.ssoDenyConsentText)}
          </TextContent>
          <Alert statusIconAriaLabel="Info">
            {formatMessage(identityConsentMessages.recommendBuilderIdText)}
          </Alert>
        </SpaceBetween>
      </>
    );
  } else {
    return (
      <TextContent>
        {formatMessage(identityConsentMessages.nonSsoDenyConsentText)}
      </TextContent>
    );
  }
};
