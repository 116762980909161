import { useEffect, useState } from 'react';
import { Button, Form } from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';

import ContainerLayout from './ContainerLayout';
import metricsService from '../services/metrics';
import HelpLink from './HelpLink';
import CardContainer from './CardContainer';
import TopHeader from './TopHeader';
import Main from './Main';
import Footer from './Footer';
import { IMPRESSIONS } from '../constants/metrics';
import { AppConfig } from '../types/app';
import errorMessages from '../i18n/error.messages';
import { handleLogoutOut } from '../services/auth';
import { rumService } from '../services/rum';
import { IdProvider } from '../types/auth';

function SignInUnauthorized({
  config,
  error,
  provider,
}: {
  config: AppConfig;
  error: Error;
  provider: IdProvider;
}) {
  const { formatMessage } = useIntl();
  const [hasLoggedOut, setHasLoggedOut] = useState(false);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const clientId = urlParams.get('client_id') || 'NA';
    const metricsPublisher = metricsService.getPublisher('SignInUnauthorized');
    metricsPublisher.publishCounterMonitor(IMPRESSIONS, 1);
    metricsPublisher.publishStringTruncate(`ClientId`, clientId);
    rumService.recordError('Sign in unauthorized');
    rumService.recordEvent('SignInUnauthorizedError', {
      error: error,
      ClientId: clientId,
      IdP: provider,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const enableCookieComponent = Boolean(
    config?.enableCookieComponent === 'true'
  );

  return (
    <>
      <TopHeader config={config} />
      <Main config={config}>
        <ContainerLayout>
          {!hasLoggedOut ? (
            <CardContainer
              header={formatMessage(errorMessages.unauthorizedHeader)}
              data-testid="unauthorized-header"
            >
              <Form
                actions={
                  <Button
                    variant="primary"
                    onClick={() => handleLogoutOut(config, setHasLoggedOut)}
                    data-testid="unauthorized-logout"
                  >
                    {formatMessage(errorMessages.unauthorizedSignOut)}
                  </Button>
                }
              >
                <p>{formatMessage(errorMessages.unauthorizedInformation)}</p>
              </Form>
            </CardContainer>
          ) : (
            <CardContainer
              header={formatMessage(
                errorMessages.unauthorizedHeaderAfterSignOut
              )}
              data-testid="unauthorized-header-signed-out"
            >
              <p>
                {formatMessage(
                  errorMessages.unauthorizedInformationAfterSignOut
                )}
              </p>
            </CardContainer>
          )}
          <HelpLink />
        </ContainerLayout>
      </Main>
      <Footer enableCookieComponent={enableCookieComponent} />
    </>
  );
}

export default SignInUnauthorized;
