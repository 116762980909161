import { defineMessages } from 'react-intl';

const accountlinkConsentMessages = defineMessages({
  pageTitle: {
    id: 'accountLink.pageTitle',
    defaultMessage: 'Account link notification',
    description: 'The page title for account link notification.',
  },
  linkingInclusiveSingleAccountFirstLine: {
    id: 'accountLink.inclusive.singleAccount.firstLine',
    defaultMessage:
      'You already have an AWS Training & Certification profile with',
    description: 'Message for Inclusive Single account linking',
  },
  linkingMultiAccountFirstLine: {
    id: 'accountLink.multiAccount.firstLine',
    defaultMessage: 'You already have an AWS Training & Certification profile',
    description: 'First line for linking multiple accounts',
  },
  linkingExclusiveSignleAccount: {
    id: 'accountLink.exclusive.singleAccount',
    defaultMessage: `You already have an AWS Training & Certification profile with <b>{userEmail}</b> using <b>{currentProviderName}</b>. Transfer your profile to this new sign-in method so you can access your activity and data. You'll still be able to sign in using <b>{currentProviderName}</b>, but existing activity and data won't appear there. This transfer will also occur across other AWS Training & Certification products you've used.`,
    description: 'Message for exclusive single account linking',
  },
  linkingExclusiveSingleAccountFirstLine: {
    id: 'accountLink.exclusive.singleAccount.firstLine',
    defaultMessage:
      'You already have an AWS Training & Certification profile with',
    description: 'Message for Inclusive Single account linking',
  },
  linkingMultipleAccounts: {
    id: 'accountLink.multipleAccounts',
    defaultMessage: `You already have an AWS Training & Certification profile with <b>{userEmail}</b>. It was last accessed {lastUsedDate} using <b>{currentProviderName}</b>. Link this new sign-in to your profile so you can access your activity and data using either. This link will also occur across other AWS Training & Certification products you've used.`,
    description: 'Message to link multiple accounts',
  },
  confirmExistingProfileOwnershipToLinkHeader: {
    id: 'accountLink.confirmExistingProfileOwnershipToLink.header',
    defaultMessage: 'Validate with {currentProvider} to link',
    description: 'Confirm that you are the owner of the existing profile',
  },
  confirmExistingProfileOwnershipButton: {
    id: 'accountLink.confirmExistingProfileOwnership.button',
    defaultMessage: 'Validate with {currentProviderName}',
    description: 'Confirm that you are the owner of the existing profile',
  },
  confirmExistingProfileOwnershipInWrongPageButton: {
    id: 'accountLink.confirmExistingProfileOwnershipInWrongPageButton.button',
    defaultMessage: 'Validate with {currentProvider}',
    description: 'Confirm that you are the owner of the existing profile',
  },
  cantConfirmExistingProfileOwnershipButton: {
    id: 'accountLink.cantConfirmExistingProfileOwnership.button',
    defaultMessage: "I can't",
    description:
      'Cannot confirm that you are the owner of the existing profile',
  },
  confirmExistingProfileOwnershipMessage: {
    id: 'accountLink.confirmExistingProfileOwnership.message',
    defaultMessage:
      'Confirm that the existing profile belongs to you by signing in with <b>{currentProviderName}</b> using <b>{userEmail}</b>.',
    description: 'Confirm that you are the owner of the existing profile',
  },
  confirmExistingProfileOwnershipExclusiveMessage: {
    id: 'accountLink.confirmExistingProfileOwnershipExclusive.message',
    defaultMessage:
      'Confirm that the existing profile belongs to you by signing in to <b>{currentProviderName}</b> using <b>{userEmail}</b>.',
    description: 'Confirm that you are the owner of the existing profile',
  },
  areYouSureYouWantSkipLinkingHeader: {
    id: 'accountLink.areYouSureYouWantSkipLinking.header',
    defaultMessage: 'Are you sure?',
    description: 'Are you sure you want to skip linking',
  },
  goBackToLoginOptionsButton: {
    id: 'accountLink.goBackToLoginOptionsButton.button',
    defaultMessage: 'Go back',
    description: 'Go back to login options',
  },
  areYouSureYouWantSkipLinkingMessageInclusive: {
    id: 'accountLink.areYouSureYouWantSkipLinkingInclusive.message',
    defaultMessage:
      "This is a one time opportunity you'll have to link. If you can't link, you'll create new profile without your existing data and activity.",
    description: 'Are you sure you want to skip linking',
  },
  areYouSureYouWantSkipLinkingMessageExclusive: {
    id: 'accountLink.areYouSureYouWantSkipLinkingExclusive.message',
    defaultMessage:
      "This is a one time opportunity you'll have to transfer. If you can't transfer, you'll create new profile without your existing data and activity.",
    description: 'Are you sure you want to skip linking',
  },
  skipAccountLinkingMessage: {
    id: 'accountLink.skipAccountLinking.message',
    defaultMessage: `This is a one time opportunity to link. By choosing not to link, you'll create a new profile without your existing data and activity. If you have an existing <b>subscription</b>, you will be <b>unable to access</b> it. If you need help validating your existing profile, <supportLink>contact Customer Support. </supportLink>`,
    description: 'Skip Account Linking message text',
  },
  skipAccountTransferMessage: {
    id: 'accountLink.skipAccountTransfer.message',
    defaultMessage: `This is a one time opportunity to transfer. By choosing not to transfer, you'll create a new profile without your existing data and activity. If you have an existing <b>subscription</b>, you will be <b>unable to access</b> it. If you need help validating your existing profile, <supportLink>contact Customer Support. </supportLink>`,
    description: 'Skip Account Transfer Message Text',
  },
  icantLinkConsentTextInclusive: {
    id: 'accountLink.icantLinkConsentInclusive.text',
    defaultMessage:
      'I understand that by proceeding not to link the profiles, <b>I may lose access to existing data, activity or subscriptions</b>',
    description: 'I cant consent text',
  },
  icantLinkConsentTextExclusive: {
    id: 'accountLink.icantLinkConsentExclusive.text',
    defaultMessage:
      'I understand that by proceeding not to transfer the profiles, <b>I may lose access to existing data, activity or subscriptions</b>',
    description: 'I cant consent text',
  },
  linkingNewSignInMethodLoadingMessage: {
    id: 'accountLink.linkingNewSignInMethodLoading.message',
    defaultMessage: 'Linking new sign-in method to your profile',
    description: 'Linking new sign-in method to your profile',
  },
  transferNewSignInMethodLoadingMessage: {
    id: 'accountLink.transferNewSignInMethodLoading.message',
    defaultMessage: 'Transfering your profile to this sign-in method',
    description: 'Transfering your profile to this sign-in method',
  },
  youHaveSignedInUsingTheWrongEmailHeader: {
    id: 'accountLink.youHaveSignedInUsingTheWrongEmail.header',
    defaultMessage: 'You have confirmed with the wrong email',
    description: 'You have signed in using the wrong email',
  },
  youHaveSignedInUsingTheWrongEmailMessage: {
    id: 'accountLink.youHaveSignedInUsingTheWrongEmail.message',
    defaultMessage: `It looks like you signed in to <b>{currentProviderName}</b> using <b>{validatedUserEmail}</b> but need to confirm profile ownership of <b>{originalUserEmail}</b> using <b>{currentProviderName}</b>.
      To try again:
      <ol>
      <li><signOutLink>Sign out</signOutLink> and return to this tab</li>
      <li>Confirm profile ownership using {currentProviderName}</li>
    </ol>`,
    description: 'You have signed in using the wrong email',
  },
  youHaveSignedInUsingTheWrongEmailMessageOtpProvider: {
    id: 'accountLink.youHaveSignedInUsingTheWrongEmailOtpProvide.message',
    defaultMessage: `It looks like you signed in to <b>{currentProviderName}</b> using <b>{validatedUserEmail}</b> but need to confirm profile ownership of <b>{originalUserEmail}</b> using <b>{currentProviderName}</b>.
      To try again:
      <ul>
      <li>Confirm profile ownership using {currentProviderName}</li>
    </ul>`,
    description: 'You have signed in using the wrong email',
  },
  youHaveSignedInUsingTheWrongEmailTryAgainMessage: {
    id: 'accountLink.youHaveSignedInUsingTheWrongEmail.tryAgainMessage',
    defaultMessage: 'To try again:',
    description: 'You have signed in using the wrong email',
  },
  confirmExistingProfileOwnershipToTransferHeader: {
    id: 'accountLink.confirmExistingProfileOwnershipToTransfer.header',
    defaultMessage: 'Validate with {currentProvider} to transfer',
    description: 'Confirm that you are the owner of the existing profile',
  },
  yesContinueWithNewProfileButton: {
    id: 'accountLink.yesContinueWithNewProfile.button',
    defaultMessage: 'Yes, continue with new profile',
    description: 'Yes, continue with new profile',
  },
  areYouSureYouWantSkipLinkingPageTitle: {
    id: 'accountLink.areYouSureYouWantSkipLinking.pageTitle',
    defaultMessage: 'Are you sure?',
    description: 'Are you sure you want to skip linking',
  },
  youHaveSignedInUsingTheWrongEmailPageTitle: {
    id: 'accountLink.youHaveSignedInUsingTheWrongEmail.pageTitle',
    defaultMessage: 'Validated Wrong Account',
    description: 'You have signed in using the wrong email',
  },
  header: {
    id: 'accountLink.profileLinking.header',
    defaultMessage: 'Profile linking',
    description: 'Account Linking Page header',
  },
  multipleLwaHeaderStepOneHeader: {
    id: 'accountLink.multipleLwaHeaderStepOneHeader.header',
    defaultMessage: 'Link this new sign-in method to an existing profile',
    description: 'Header for Multiple LWA profiles',
  },
  multipleExclusiveHeader: {
    id: 'accountLink.multipleLwaExlusiveHeaderStepOneHeader.header',
    defaultMessage: 'Transfer your existing profile to this new sign-in method',
    description: 'Header for Multiple Exclusive profiles',
  },
  multipleLwaHeaderStepOneContent: {
    id: 'accountLink.multipleLwaHeaderStepOneContent.content',
    defaultMessage: `You already have multiple AWS Training & Certification profiles with <b>{originalUserEmail}</b> using <b>Login with Amazon</b> (using different retail sites). 
    {br}
    Link this new sign-in method to your preferred existing profile so you can access your activity and data using either sign-in method. The link will also occur across other AWS Training & Certification products you've used.`,
    description: 'Content for multiple LWA profiles',
  },
  continueToNextStepButton: {
    id: 'accountLink.continueToNextStepButton.label',
    defaultMessage: 'Continue to next step',
    description: 'Button text for continue to next step',
  },
  signOutToAllowSignInHeaderStepOne: {
    id: 'accountLink.signOutToAllowSignInHeaderStepOne.text',
    defaultMessage: `Step 1: Sign out to allow sign in again`,
    description: 'Description for Sign out to allow sign in',
  },
  signOutToAllowSignInContent: {
    id: 'accountLink.signOutToAllowSignInContent.text',
    defaultMessage: `To link, you’ll need to sign in using <b>{originalUserEmail}</b> and the password that matches one of your existing AWS Training & Certification profiles. Sign out to allow sign in to the Login with Amazon account you want to validate with.`,
    description: 'Description for Sign out to allow sign in',
  },
  returnToThisTabToValidate: {
    id: 'accountLink.returnToThisTabToValidate.text',
    defaultMessage: `Once you sign out, <b>return to this tab</b> to validate.`,
    description: 'Alert text to return to this tab to validate',
  },
  signOutToAllowSignInButton: {
    id: 'accountLink.signOutToAllowSignInButton.text',
    defaultMessage: 'Sign out to allow sign in again',
    description: 'Button Label for Sign Out to Sign In Again',
  },
  signOutToAllowSignInHeaderStepTwoHeader: {
    id: 'accountLink.signOutToAllowSignInHeaderStepTwoHeader.text',
    defaultMessage: `Step 2: Validate with Login with Amazon to link`,
    description: 'Description for Sign out to allow sign in',
  },
  signOutToAllowSignInStepTwoContent: {
    id: 'accountLink.signOutToAllowSignInStepTwoContent.text',
    defaultMessage: `Link your preferred existing profile by signing in with Login with Amazon using <b>{originalUserEmail}</b>. We recommend linking with the profile you use the most. This link will also occur across other AWS Training & Certification products you’ve used. `,
    description: 'Description for Sign out to allow sign in',
  },
  validateWithIdpButton: {
    id: 'accountLink.validateWithIdpButton.text',
    defaultMessage: 'Validate with Login with Amazon',
    description: 'Button Label for Sign Out to Sign In Again',
  },
  lwaToLwaContentText: {
    id: 'accountLink.lwaToLwaContentText',
    defaultMessage: `You already have an AWS Training & Certification profile with <b>{originalUserEmail}</b> using <b>Login with Amazon</b> (last accessed {lastUsedDate}).

    Link this new sign-in method to your existing profile so you can access your activity and data using either sign-in method.  The link will also occur across other AWS Training & Certification products you've used.`,
    description: 'Content for Lwa to Lwa linking',
  },
  pleaseTellUsWhyHeading: {
    id: 'accountLink.pleaseTellUsWhyHeading',
    defaultMessage: `Please tell us why`,
    description: 'I cant survey workflow',
  },
  pleaseTellUsWhyQuestion: {
    id: 'accountLink.pleaseTellUsWhyQuestion',
    defaultMessage: `Why did you choose not to link this new sign-in method to your existing profile?`,
    description: 'I cant survey workflow',
  },
  chooseOneLabel: {
    id: 'accountLink.chooseOneLabel',
    defaultMessage: `Choose one`,
    description: 'Choose One Label',
  },
  pleaseTellUsWhyQuestionOptionOne: {
    id: 'accountLink.pleaseTellUsWhyQuestionOptionOne',
    defaultMessage: `I’m unable to validate my existing profile right now`,
    description: 'Option one Text',
  },
  pleaseTellUsWhyQuestionOptionTwo: {
    id: 'accountLink.pleaseTellUsWhyQuestionOptionTwo',
    defaultMessage: `I don’t want to link this sign-in method to my existing profile`,
    description: 'Option two Text',
  },
  otherOptionText: {
    id: 'accountLink.OtherOptionText',
    defaultMessage: 'Other',
    description: 'Text for option other',
  },
  getHelpWithThisProfile: {
    id: 'accountLink.getHelpWithThisProfile',
    defaultMessage: `To get help linking this profile, contact <supportLink>Customer Support</supportLink>`,
    description: 'Support Link for get help with this profile',
  },
  goBackToICantOptionsButton: {
    id: 'accountLink.goBackToICantOptionsButton.button',
    defaultMessage: 'Back',
    description: 'Go back to I cant options',
  },
  continueICantFlwoButton: {
    id: 'accountLink.continueICantFlwoButton.button',
    defaultMessage: 'Continue',
    description: 'Continue with I cant options',
  },
  youHaveSignedInWithWrongAccount: {
    id: 'accountLink.youHaveSignedInWithWrongAccount.Header',
    defaultMessage: 'You signed in with the wrong account',
    description: 'Header when user validated with wrong account',
  },
  youHaveSignedInWithWrongAccountContent: {
    id: 'accountLink.youHaveSignedInWithWrongAccountContent.text',
    defaultMessage: `To link, you need to validate using {currentProviderName} with an existing AWS Training & Certification profile using <b>{originalUserEmail}</b>. Please sign out to allow sign in again.`,
    description: 'Header when user validated with wrong account',
  },
  pleaseReturnToThisTabToValidate: {
    id: 'accountLink.pleaseReturnToThisTabToValidate.text',
    defaultMessage: `Please <b>return to this tab</b> after signing out to sign in again.`,
    description: 'Alert text to return to this tab to validate',
  },
  confirmExistingProfileOwnershipInWrongPageHeader: {
    id: 'accountLink.confirmExistingProfileOwnershipInWrongPageHeader.text',
    defaultMessage: 'Validate with {currentProvider}',
    description: 'Confirm that you are the owner of the existing profile',
  },
  confirmExistingProfileOwnershipInWrongPageContent: {
    id: 'accountLink.confirmExistingProfileOwnershipInWrongContent.text',
    defaultMessage: `Link an existing profile by signing in with {currentProvider} using <b>{originalUserEmail}</b>. This link will also occur across other AWS Training & Certification products you’ve used. `,
    description: 'Confirm that you are the owner of the existing profile',
  },
  confirmExistingProfileOwnershipWrongAccountFlowButton: {
    id: 'accountLink.confirmExistingProfileOwnershipWrongAccountFlowButton.button',
    defaultMessage: 'Validate with {currentProviderName}',
    description: 'Confirm that you are the owner of the existing profile',
  },
  somethingWentWrongHeader: {
    id: 'accountLink.SomethingWentWrong.header',
    defaultMessage: `Something went wrong`,
    description: 'Account Link Error Header',
  },
  somethingWentWrongWithLinkContent: {
    id: 'accountLink.SomethingWentWrongLink.header',
    defaultMessage: `We were unable to link this sign-in method to your profile. Please try signing in with {newProvider} again.`,
    description: 'Account Link Error linking',
  },
  somethingWentWrongWithTransferContent: {
    id: 'accountLink.SomethingWentWrongTransfer.header',
    defaultMessage: `We were unable to transfer your profile to this sign-in method. Please try signing in with [new IDP] again.`,
    description: 'Account Link Error content for transfer',
  },
  somethingWentWrongSignInButton: {
    id: 'accountLink.somethingWentWrongSignInButton.button',
    defaultMessage: `Sign in`,
    description: 'Account Link Error Header',
  },
  multipleProfileLinkingWithOptionsContent: {
    id: 'accountLink.multipleProfileWithOptionsContent.text',
    defaultMessage: `You already have multiple existing AWS Training & Certification profiles with <b>{originalUserEmail}</b>. Link this new sign-in method to your preferred existing profile so you can access your activity and data using either sign-in method. The link will also occur across other AWS Training & Certification products you've used.`,
    description: 'Multiple Profiles for linking with options',
  },
  chooseSignInMethod: {
    id: 'accountLink.ChooseSignInMethod',
    defaultMessage:
      'Choose the sign-in method that has the profile you want to validate with:',
    description: 'Choose signIn Method text',
  },
  singleProfileLinkingWithOptionsContent: {
    id: 'accountLink.singleProfileLinkingWithOptionsContent.text',
    defaultMessage: `You already have an existing AWS Training & Certification profile with <b>{originalUserEmail}</b>. Link this new sign-in method to your existing profile so you can access your activity and data using either sign-in method.  The link will also occur across other AWS Training & Certification products you've used.`,
    description: 'Multiple Profiles for linking with options',
  },
  chooseSignInMethodSingle: {
    id: 'accountLink.chooseSignInMethodSingle',
    defaultMessage: 'Choose the sign-in method you want to validate with:',
    description: 'Choose signIn Method text',
  },
  singleProfileExclusiveWithOptionsContent: {
    id: 'accountLink.singleProfileExclusiveWithOptionsContent.text',
    defaultMessage: `You already have an AWS Training & Certification profile with {currentUserEmail} using {existingProvider}. Transfer your profile to this new sign-in method so you can access your activity and data. You’ll still be able to sign in using {existingProvider}, but existing activity and data won’t appear there. This transfer will also occur across other AWS Training & Certification products you’ve used.

    Confirm that the existing profile belongs to you by signing in with {existingProvider} using {currentUserEmail}.`,
    description: 'Multiple Profiles for linking with options',
  },
  multipleProfileExclusiveWithOptionsContent: {
    id: 'accountLink.multipleProfileExclusiveWithOptionsContent.text',
    defaultMessage: `You already have multiple existing AWS Training & Certification profiles with {currentUserEmail}. Transfer your preferred existing profile to this new sign-in method so you can access your activity and data. You’ll still be able to sign in using these profile(s), but existing activity and data won’t appear there. This transfer will also occur across other AWS Training & Certification products you’ve used.`,
    description: 'Multiple Profiles for linking with options',
  },
  renderIdpSelectAlert: {
    id: 'accountLink.renderIdpSelectAlert.text',
    defaultMessage: `Please select one of the profile(s), if you wish to continue`,
    description: 'Multiple Profiles for linking with options',
  },
});

export default accountlinkConsentMessages;
