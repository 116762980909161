import { Button } from '@amzn/awsui-components-react';
import React, { useState } from 'react';
import { postConsent } from '../services/consent';
import { AppConfig } from '../types/app';
import { GandalfUser } from '../types/auth';
import { useNonceGenerator } from '../contexts/NonceGeneratorContextProvider';

export const AcceptConsent = ({
  onContinue,
  buttonLabel,
  config,
  user,
  setConsentAccepted,
}: {
  onContinue: () => void;
  buttonLabel: string;
  config: AppConfig;
  user: GandalfUser;
  setConsentAccepted: (isAccepted: boolean) => void;
}) => {
  const nonceGenerator = useNonceGenerator();
  const params = new URLSearchParams(window.location.search);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const handleAcceptConsent = async () => {
    setIsLoading(true);
    await postConsent(
      config.gandalfProfileEndpoint,
      true,
      user,
      nonceGenerator,
      params,
      config
    );
    setIsLoading(false);
    setConsentAccepted(true);
    onContinue();
  };
  return (
    <Button
      onClick={handleAcceptConsent}
      variant="primary"
      data-testid={'accept-consent'}
      loading={isLoading}
    >
      {buttonLabel}
    </Button>
  );
};
