import { TextContent } from '@amzn/awsui-components-react';
import React from 'react';

const LinkButton = ({
  children,
  onClick,
  ...props
}: {
  children: React.ReactNode;
  onClick: Function;
}) => {
  return (
    <TextContent>
      <a
        // The link functions like an accessible button, this requires some
        // additional event listeners.
        role="button"
        // Not used but needed.
        href={document.location.href}
        onClick={(event) => {
          event.preventDefault();
          onClick(event);
        }}
        // Prevent space from scrolling content.
        onKeyDown={(event) => {
          if (event.key === ' ') event.preventDefault();
        }}
        // Space should activate the button.
        onKeyUp={(event) => {
          if (event.key === ' ') {
            event.preventDefault();
            onClick(event);
          }
        }}
        // Prevent ex middle clicks which opens links in a new tab.
        onAuxClick={(event) => event.preventDefault()}
        {...props}
      >
        {children}
      </a>
    </TextContent>
  );
};

export default LinkButton;
