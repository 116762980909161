import { TextContent } from '@amzn/awsui-components-react';
import { Component, ErrorInfo } from 'react';
import { injectIntl, IntlShape } from 'react-intl';
import { IMPRESSIONS } from '../constants/metrics';

import errorMessages from '../i18n/error.messages';
import metricsService from '../services/metrics';
import { rumService } from '../services/rum';
import logger from '../utils/logger';

class ErrorBoundary extends Component<{
  FallbackComponent?: React.ReactType;
  intl: IntlShape;
}> {
  public state = {
    hasError: false,
    error: null,
  };

  static getDerivedStateFromError(error: Error | null) {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    logger.error('ErrorBoundary', error, errorInfo);
    rumService.recordError(error);
    // Publishing metrics is handled by the FallbackComponent if provided.
    if (this.props.FallbackComponent) return;

    // Publish error metrics
    const urlParams = new URLSearchParams(window.location.search);
    const clientId = urlParams.get('client_id');
    const metricsPublisher = metricsService.getPublisher(
      'GenericErrorBoundary'
    );
    metricsPublisher.publishCounterMonitor(IMPRESSIONS, 1);
    metricsPublisher.publishStringTruncate(`ClientId`, clientId || 'NA');
    metricsPublisher.publishStringTruncate('Error', error.message || 'NA');
  }

  render() {
    if (!this.state.hasError) return this.props.children;

    const FallbackComponent = this.props.FallbackComponent;
    return FallbackComponent ? (
      <FallbackComponent
        details={this.state.error && (this.state.error as any).toString()}
      />
    ) : (
      <TextContent>
        {this.props.intl.formatMessage(
          errorMessages.somethingWrongPleaseReload
        )}
      </TextContent>
    );
  }
}

export default injectIntl(ErrorBoundary);
