import { AWSCShortbread } from '@amzn/shortbread';
import UXDefinitions from '@amzn/shortbread/dist/definitions/ux-definitions';
import {
  COOKIE_REGISTRY,
  PROD_DOMAINS,
  SHORTBREAD_BUTTON_ID,
  SHORTBREAD_CONTAINER_ID,
  SHORTBREAD_ELEMENT_ID,
} from '../constants/cookies';
import { rumService } from './rum';

const isLocalhost = window.location.hostname === 'localhost';

export const getCookieDomain = (hostname: string) => {
  for (const prodDomain of PROD_DOMAINS) {
    if (hostname.indexOf(prodDomain) !== -1) return prodDomain;
  }
  // If the domain is not found in prod, return the requested hostname to avoid setting the wrong domain.
  return hostname;
};

/**
 * Used to force the cookie banner when testing in localhost without having to connect to a EU VPN.
 * The native shortbread queryGeolocation function will be used otherwise.
 */
const replaceGeolocationFn = isLocalhost
  ? (geolocatedIn: any) => geolocatedIn('EU')
  : undefined;

const focusCookiePreferencesButton = () => {
  const $shortbreadButton = document.getElementById(SHORTBREAD_BUTTON_ID);
  if (!$shortbreadButton) return;
  $shortbreadButton.focus();
};

// Cache the shortbread instance
let shortbreadInstance: AWSCShortbread;

export const createShortbreadInstance = (locale: string) => {
  // Shortbread does not support changing the locale language dynamically,
  // this means we need to remove the mounted element manually.
  // See related Shortbread recommendation: https://w.amazon.com/bin/view/Shortbread/usage
  const existingShortbreadEl = document.getElementById(SHORTBREAD_ELEMENT_ID);
  if (existingShortbreadEl) existingShortbreadEl.remove();

  shortbreadInstance = AWSCShortbread({
    parent: document.getElementById(SHORTBREAD_CONTAINER_ID)!,
    domain: getCookieDomain(window.location.hostname),
    language: (locale as UXDefinitions.LanguageCodeOptions) || 'en-US',
    queryGeolocation: replaceGeolocationFn,
    onModalClose: focusCookiePreferencesButton,
    onConsentChanged: () => {
      rumService.updateCookieConsent();
    },
    registry: COOKIE_REGISTRY,
  });
  shortbreadInstance.checkForCookieConsent();
};

/**
 * @returns Shortbread instance
 */
export const getShortbread = () => {
  if (!shortbreadInstance) throw new Error('Need to initialize Shortbread.');
  return shortbreadInstance;
};
