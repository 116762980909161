import { buildNonce } from './auth';
import { IDP_URL_PARAM } from '../constants/auth';
import { AuthToken, deriveOtpUrl, storeAuthToken } from '../utils/authToken';
import Cookies from 'js-cookie';
import {
  VERIFY_EMAIL_COOKIE_EXPIRATION_MS,
  VERIFY_EMAIL_COOKIE_NAME,
} from '../components/postAuth/VerifyEmail';
import { NonceGenerator } from '../contexts/NonceGeneratorContextProvider';
import { GandalfUser } from '../types/auth';
import { AppConfig } from '../types/app';

const startEmailVerification = (
  nonceGenerator: NonceGenerator,
  params: URLSearchParams,
  user: GandalfUser,
  config: AppConfig
) => {
  // store current auth token
  const nonce = buildNonce(nonceGenerator);
  params.set(IDP_URL_PARAM, user.providerName);
  const authToken: AuthToken = {
    originalRequestURL: params.toString(),
    jwtToken: user.jwtToken ?? '',
    nonce,
  };
  storeAuthToken(authToken);

  // create cookie to indicate that OTP email should immediately be sent
  Cookies.set(VERIFY_EMAIL_COOKIE_NAME, user.email!, {
    expires: new Date(new Date().getTime() + VERIFY_EMAIL_COOKIE_EXPIRATION_MS),
    sameSite: 'Strict',
    secure: true,
  });

  // redirect to OTP IDP
  const redirect_url = `${window.location.origin}/verifyemails`;
  const otpUrl = deriveOtpUrl(config, nonce, redirect_url);
  window.location.assign(otpUrl);
};

export default startEmailVerification;
