const idSeparator = ':';
const questionSuffix = `${idSeparator}question`;
const answerSuffix = `${idSeparator}answer`;

/**
 * Returns help QnA pairs, generated from the inputted react-intl messages. The
 * message IDs are espected to follow a pattern where the first part of the key
 * ID is shared in both the question and answer: `KEY_ID:question` and `KEY_ID:answer`.
 *
 * @example
 * getI18nHelpContentPairs({
 *   'help1:question': {
 *     id: 'someid.question',
 *     defaultMessage: 'Y tho?',
 *   },
 *   'help1:answer': {
 *     id: 'someid.answer',
 *     defaultMessage: 'This is the way.',
 *   }
 * })
 * // returns
 * [{
 *   question: { id: 'someid.question', ... },
 *   answer: { id: 'someid.answer', ...}
 * }]
 */
export const getHelpContentMessagePairs = (
  helpMessages: Record<string, any>
) => {
  const helpKeyIds = Object.keys(helpMessages).reduce<Record<string, any>>(
    (acc, key) => {
      const helpId = key.split(idSeparator)[0];
      acc[helpId] = true;
      return acc;
    },
    {}
  );
  return Object.keys(helpKeyIds).map((key) => {
    const question = helpMessages[`${key}${questionSuffix}`];
    const answer = helpMessages[`${key}${answerSuffix}`];
    if (!question || !answer)
      throw new Error(`Missing help content pair for '${key}'`);
    return {
      question,
      answer,
    };
  });
};
