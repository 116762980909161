import { defineMessages } from 'react-intl';

const eulaMessages = defineMessages({
  header: {
    id: 'eula.header',
    defaultMessage: 'Terms and conditions',
    description: 'Header of the EULA page.',
  },
  cancelButton: {
    id: 'eula.cancelButton',
    defaultMessage: 'Cancel',
    description: 'Button to cancel accepting the EULA.',
  },
  acceptButton: {
    id: 'eula.acceptButton',
    defaultMessage: 'Accept terms',
    description: 'Button to accept the EULA terms.',
  },
  eulaInformation: {
    id: 'eula.eulaInformation',
    defaultMessage:
      'By participating in AWS Training, you agree to be bound by the <termsLink>AWS Learner Terms and Conditions</termsLink>. The information you provide in connection with AWS Training will be handled in accordance with the <privacyLink>AWS Privacy Notice</privacyLink>.',
    description: 'EULA terms information.',
  },
  eulaUpdateError: {
    id: 'eula.eulaUpdateError',
    defaultMessage:
      'An error occurred while updating the terms and conditions status. Please try again.',
    description: 'Error when eula update fails.',
  },
});

export default eulaMessages;
