import React, { useRef, useEffect } from 'react';
import Container from '@amzn/awsui-components-react/polaris/container';
import Header from '@amzn/awsui-components-react/polaris/header';
import Box from '@amzn/awsui-components-react/polaris/box';
import Grid from '@amzn/awsui-components-react/polaris/grid';

import metricsService from '../services/metrics';
import usePageTitle from '../hooks/usePageTitle';
import TopHeader from '../components/TopHeader';
import Main from '../components/Main';
import Footer from '../components/Footer';
import { AppConfig } from '../types/app';
import { useIntl } from 'react-intl';
import helpMessages from '../i18n/help.messages';
import newHelpMessages from '../i18n/newHelp.messages';
import { getHelpContentMessagePairs } from '../utils/helpContent';
import HelpTopics from '../components/HelpTopics';
import styles from './Help.module.css';
import { accountLinkingHelpMessages } from '../i18n/newHelpContent.messages';

const newAccountLinkingHelp = getHelpContentMessagePairs(
  accountLinkingHelpMessages
);

const SectionHeader = ({ children }: { children: React.ReactNode }) => (
  <h2 data-testid="help-section">{children}</h2>
);

const AccountLinkFAQ = ({ config }: { config: AppConfig }) => {
  const { formatMessage } = useIntl();
  usePageTitle(formatMessage(helpMessages.pageTitle));
  const metricsPublisher = useRef(
    metricsService.getPublisher('AccountLinkFAQ')
  );

  useEffect(() => {
    metricsPublisher.current.publishCounterMonitor('Impressions', 1);
  }, []);

  const enableCookieComponent = Boolean(
    config?.enableCookieComponent === 'true'
  );
  return (
    <>
      <TopHeader config={config} />
      <Main config={config}>
        <Box margin={{ top: 'xxl', bottom: 'xxl' }} className={styles.maxWidth}>
          <Grid
            disableGutters
            gridDefinition={[
              {
                colspan: { xxs: 10, s: 8, xl: 6 },
                offset: { xxs: 1, s: 2, xl: 3 },
              },
            ]}
          >
            <Container
              header={
                <Header variant="h1" data-testid="help-header">
                  {formatMessage(helpMessages.headerTitle)}
                </Header>
              }
            >
              <Box margin={{ top: 'l' }}>
                <SectionHeader>
                  {formatMessage(newHelpMessages.sectionAccountLinking)}
                </SectionHeader>
                <HelpTopics
                  helpTopics={newAccountLinkingHelp}
                  config={config}
                />
              </Box>
            </Container>
          </Grid>
        </Box>
      </Main>
      <Footer enableCookieComponent={enableCookieComponent} />
    </>
  );
};

export default AccountLinkFAQ;
