import React, { useRef, useEffect } from 'react';
import Header from '@amzn/awsui-components-react/polaris/header';
import Box from '@amzn/awsui-components-react/polaris/box';
import Grid from '@amzn/awsui-components-react/polaris/grid';

import metricsService from '../services/metrics';
import usePageTitle from '../hooks/usePageTitle';
import TopHeader from '../components/TopHeader';
import Main from '../components/Main';
import Footer from '../components/Footer';
import { AppConfig } from '../types/app';
import { useIntl } from 'react-intl';
import helpMessages from '../i18n/help.messages';
import newHelpMessages from '../i18n/newHelp.messages';
import { getHelpContentMessagePairs } from '../utils/helpContent';
import {
  faqMessages,
  troubleshootingMessages,
} from '../i18n/helpContent.messages';
import HelpTopics from '../components/HelpTopics';
import styles from './Help.module.css';
import {
  accountLinkingHelpMessages,
  signingInHelpMessages,
} from '../i18n/newHelpContent.messages';
import CardContainer from '../components/CardContainer';
import { SpaceBetween } from '@amzn/awsui-components-react';
import HelpContainerLayout from '../components/HelpContainerLayout';

const faqContent = getHelpContentMessagePairs(faqMessages);
const troubleshootingContent = getHelpContentMessagePairs(
  troubleshootingMessages
);

const newSigningHelp = getHelpContentMessagePairs(signingInHelpMessages);
const newAccountLinkingHelp = getHelpContentMessagePairs(
  accountLinkingHelpMessages
);

const SectionHeader = ({ children }: { children: React.ReactNode }) => (
  <h2 data-testid="help-section">{children}</h2>
);

const Help = ({ config }: { config: AppConfig }) => {
  const { formatMessage } = useIntl();
  usePageTitle(formatMessage(helpMessages.pageTitle));
  const metricsPublisher = useRef(metricsService.getPublisher('Help'));

  useEffect(() => {
    metricsPublisher.current.publishCounterMonitor('Impressions', 1);
  }, []);

  const enableNewHelpGuide: boolean = true;

  const enableCookieComponent = Boolean(
    config?.enableCookieComponent === 'true'
  );
  return (
    <>
      <TopHeader config={config} />
      <Main config={config}>
        <Box margin={{ top: 'xxl', bottom: 'xxl' }} className={styles.maxWidth}>
          <Grid
            disableGutters
            gridDefinition={[
              {
                colspan: { xxs: 10, s: 8, xl: 6 },
                offset: { xxs: 1, s: 2, xl: 3 },
              },
            ]}
          >
            <HelpContainerLayout>
              <SpaceBetween direction="vertical" size="l">
                <Header variant="h1" data-testid="help-header">
                  {formatMessage(helpMessages.headerTitle)}
                </Header>
                <CardContainer>
                  <Box margin={{ top: 'l' }}>
                    <SectionHeader>
                      {enableNewHelpGuide
                        ? formatMessage(newHelpMessages.sectionSingingIn)
                        : formatMessage(helpMessages.sectionTroubleshooting)}
                    </SectionHeader>
                    <HelpTopics
                      helpTopics={
                        enableNewHelpGuide
                          ? newSigningHelp
                          : troubleshootingContent
                      }
                      config={config}
                    />
                  </Box>
                </CardContainer>
                <div id="account-link-help">
                  <CardContainer>
                    <Box margin={{ top: 'l' }}>
                      <SectionHeader>
                        {enableNewHelpGuide
                          ? formatMessage(newHelpMessages.sectionAccountLinking)
                          : formatMessage(helpMessages.sectionFAQ)}
                      </SectionHeader>
                      <HelpTopics
                        helpTopics={
                          enableNewHelpGuide
                            ? newAccountLinkingHelp
                            : faqContent
                        }
                        config={config}
                      />
                    </Box>
                  </CardContainer>
                </div>
              </SpaceBetween>
            </HelpContainerLayout>
          </Grid>
        </Box>
      </Main>
      <Footer enableCookieComponent={enableCookieComponent} />
    </>
  );
};

export default Help;
