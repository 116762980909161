import { useEffect } from 'react';
import { useIntl } from 'react-intl';
import globalMessages from '../i18n/global.messages';

const usePageTitle = (title?: string) => {
  const { formatMessage } = useIntl();
  useEffect(() => {
    document.title = title
      ? `${title} - ${formatMessage(globalMessages.pageTitle)}`
      : formatMessage(globalMessages.pageTitle);
  }, [title, formatMessage]);
};

export default usePageTitle;
