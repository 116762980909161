import { createContext, useContext } from 'react';

export interface NonceGenerator {
  generateNonce(buf: ArrayBuffer): void;
}

export const noopNonceGenerator: NonceGenerator = {
  generateNonce(_buf) {
    // noop
  },
};

export const NonceGeneratorContext =
  createContext<NonceGenerator>(noopNonceGenerator);

export const NonceGeneratorContextProvider = NonceGeneratorContext.Provider;

export function useNonceGenerator() {
  return useContext(NonceGeneratorContext);
}
