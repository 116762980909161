import { defineMessages } from 'react-intl';

const signinMessages = defineMessages({
  header: {
    id: 'signin.header',
    defaultMessage: 'Choose a sign in method',
    description: 'Main heading for sign in page.',
  },
  changeSigninOption: {
    id: 'signin.changeSigninOption',
    defaultMessage: 'Sign in with a different option',
    description: 'Action to see all available sign in options.',
  },
  organizationSigninButton: {
    id: 'signin.organizationSigninButton',
    defaultMessage: 'Organization SSO',
    description: 'Action to select to sign in through an organization.',
  },
  organizationSigninInfo: {
    id: 'signin.organizationSigninInfo',
    defaultMessage:
      'For enterprise single sign-on (SSO), please visit your company’s direct URL.',
    description:
      'Information to customers wanting to sign in through an organization.',
  },
  signInMechanismSeparator: {
    id: 'signin.signInMechanismSeparator',
    defaultMessage: 'or',
    description:
      'Visually separates sign in mechanisms from each other vertically, adding "or" In between.',
  },
  signinButton: {
    id: 'signin.signinButton',
    defaultMessage: 'Sign in',
    description: 'Signin button label for Singin page',
  },
  createOrSignInButton: {
    id: 'signin.createOrSignInButton',
    defaultMessage: 'Create or Sign in',
    description: 'Create or Signin Button for the Signin page',
  },
  linkAvailable: {
    id: 'signin.linkAvailable',
    defaultMessage: `<b>For return customers signing in with AWS Builder ID for the first time</b> When you create an AWS Builder ID using the same email address you use to sign in with Login with Amazon we will link your learning activity and data. <b><accountLinkHelp> Learn more </accountLinkHelp></b>`,
    description: 'info message',
  },
  learnMoreLabel: {
    id: 'signin.learnMore',
    defaultMessage: '<builderIdHelp>Learn more</builderIdHelp>',
    description: 'Learn more label',
  },
  essoSignInDescription: {
    id: 'signin.esso',
    defaultMessage:
      'Use your organization email (available for select companies)',
    description: 'ESSO signin description',
  },
  signInMechanismSeparatoLowerCase: {
    id: 'signin.signInMechanismSeparator',
    defaultMessage: 'or',
    description:
      'Visually separates sign in mechanisms from each other vertically, adding "or" In between.',
  },
  newSignInBanner: {
    id: 'signin.newSignInBanner',
    defaultMessage: `<b>Announcing a new sign in method, AWS Builder ID!</b> {br} If you previously signed in with Login with Amazon you can link your existing profile to your AWS Builder ID if it uses the same email. <b><accountLinkHelp>Learn more about linking</accountLinkHelp></b>`,
    description: 'New Signin banner',
  },
  essoInputPlaceHolder: {
    id: 'signin.essoInputPlaceholder',
    defaultMessage: 'Your organization email',
    description: 'Placeholder text ESSO email Input',
  },
  builderIdRecommendation: {
    id: 'signin.builderIdRecommendation',
    defaultMessage: 'Recommended for individuals',
    description: 'Recommended badge for builder ID',
  },
  apnBadgeRecommendation: {
    id: 'signin.apnBadgeRecommendation.text',
    defaultMessage: 'For AWS Partners',
    description: 'APN badge',
  },
  preferredIdpNotFound: {
    id: 'signin.preferredIdpNotFound',
    defaultMessage:
      'The selected sign in method was not found. Choose a different sign in method to continue.',
    description:
      'Warning message when the selected sign in method was not found',
  },
  preferredIdpError: {
    id: 'signin.preferredIdpError',
    defaultMessage:
      'Oh no, something went wrong. Please refresh the page, or choose a different sign in method to continue.',
    description:
      'Error message when configuration could not be retrieved for the selected sign in method',
  },
});

export default signinMessages;
