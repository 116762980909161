import { defineMessages } from 'react-intl';

const learnerConsentOnMerge3pMessages = defineMessages({
  header: {
    id: 'learnerConsentOnMerge3p.header',
    defaultMessage: 'Join {orgName}',
    description: 'Header of the 3P consent page.',
  },
  organizationDataAccessNoticeHeader: {
    id: 'learnerConsentOnMerge3p.organizationDataAccessNoticeHeader',
    defaultMessage: 'Organization Data Access Notice',
    description: 'Notice of organization data access',
  },
  acceptButton: {
    id: 'learnerConsentOnMerge3p.acceptButton',
    defaultMessage: 'Accept',
    description: 'Accept button',
  },
  cancelButton: {
    id: 'learnerConsentOnMerge3p.cancelButton',
    defaultMessage: 'Cancel',
    description: 'Button to cancel.',
  },
  doNotJoinOrganization: {
    id: 'learnerConsentOnMerge3p.doNotJoinOrganization',
    defaultMessage: 'Do not join organization',
    description: 'Do not join organization.',
  },
  continueToIndividualAccount: {
    id: 'learnerConsentOnMerge3p.continueToIndividualAccount',
    defaultMessage: 'Continue to Individual account',
    description: 'Continue to Individual account.',
  },
  continueToSignOut: {
    id: 'learnerConsentOnMerge3p.continueToSignOut',
    defaultMessage: 'Continue to sign out',
    description: 'Continue to sign out.',
  },
  learnerConsentOnMerge3pDescription: {
    id: 'learnerConsentOnMerge3p.description',
    defaultMessage:
      'By joining <b>{orgName}</b> to take AWS Training, you agree <b>{orgName}</b> will have access to your profile information, preferences, and training data pursuant to the <termsLink>AWS Learner Terms and Conditions</termsLink> and <privacyLink>AWS Privacy Notice</privacyLink>.',
    description: 'Learner Consent On Merge 3P description.',
  },
  verificationError: {
    id: 'learnerConsentOnMerge3p.verificationError',
    defaultMessage:
      'An error occurred while completing Learner Consent On Merge. Please try again.',
    description: 'Error when Learner Consent On Merge 3P completion fails.',
  },
  confirmationModalHeader: {
    id: 'learnerConsentOnMerge3p.confirmationModalHeader',
    defaultMessage: 'Are you sure?',
    description: 'Header for confirmation modal.',
  },
  confirmationModalDescriptionNonEsso: {
    id: 'learnerConsentOnMerge3p.confirmationModalDescriptionNonEsso',
    defaultMessage:
      'If you decline to join, you will not be able to access the training provided by this organization and your account will remain in an individual status. To join later, contact your learning administrator for a new invitation.',
    description: 'Description for confirmation modal.',
  },
  confirmationModalDescriptionEsso: {
    id: 'learnerConsentOnMerge3p.confirmationModalDescriptionEsso',
    defaultMessage:
      "You chose not to join the organization. If you want to join your Organization's AWS training in the future, sign-in again with your single sign-on credentials.",
    description: 'Description for confirmation modal.',
  },
});

export default learnerConsentOnMerge3pMessages;
