import React from 'react';
import { IdProvider } from '../types/auth';
import { IdPs } from '../constants/providers';
import { useIntl } from 'react-intl';
import { providerNameDiscriptions } from '../i18n/provider.messages';

import { Link, TextContent } from '@amzn/awsui-components-react';
import { lwaDeprecationMessages } from '../i18n/lwaDeprecation.messages';

const IdpDescription = ({
  provider,
}: {
  provider: IdProvider;
}): React.ReactNode => {
  const { formatMessage } = useIntl();

  if (provider.idp === IdPs.AWSBuilderID) {
    return (
      <>
        <TextContent>
          {formatMessage(lwaDeprecationMessages.builderIdProviderDescription)}
          <br />
          {formatMessage(lwaDeprecationMessages.builderIdProviderNote, {
            b: (str) => <b>{str}</b>,
          })}
          <br />
          {formatMessage(lwaDeprecationMessages.builderIdLearnMoreSupportLink, {
            supportLink: (str) => (
              <>
                <Link
                  href="https://docs.aws.amazon.com/signin/latest/userguide/sign-in-aws_builder_id.html?icmpid=docs_builder_id_console"
                  target="_blank"
                  rel="noreferrer"
                  external={true}
                  externalIconAriaLabel="AWS Builder ID User Guide (opens in a new tab)"
                >
                  {str}
                </Link>
              </>
            ),
          })}
        </TextContent>
      </>
    );
  } else {
    return (
      <TextContent>
        {IdPs[provider.idp as IdPs]
          ? formatMessage(
              providerNameDiscriptions[provider.idp as IdPs] ?? '',
              {
                br: <br />,
                builderIdHelp: (str) => (
                  <>
                    <Link
                      href="https://docs.aws.amazon.com/signin/latest/userguide/sign-in-aws_builder_id.html?icmpid=docs_builder_id_console"
                      target="_blank"
                      rel="noreferrer"
                      external={true}
                      externalIconAriaLabel="AWS Builder ID User Guide (opens in a new tab)"
                    >
                      {str}
                    </Link>
                  </>
                ),
                apnHelp: (str) => (
                  <Link
                    href="https://aws.amazon.com/partners/"
                    target="_blank"
                    rel="noreferrer"
                    external={true}
                    externalIconAriaLabel="AWS Partner Network User Guide (opens in a new tab)"
                  >
                    {str}
                  </Link>
                ),
              }
            )
          : ' '}
      </TextContent>
    );
  }
};

export default IdpDescription;
