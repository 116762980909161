import { useIntl } from 'react-intl';

import logo from '../assets/logo-color-dark.svg';
import globalMessages from '../i18n/global.messages';
import styles from './TopHeader.module.css';
import LocaleMenu from './LocaleMenu';
import { AppConfig } from '../types/app';
import TopNavigationBar from './TopNavigationBar';

const TopHeader = ({ config }: { config?: AppConfig }) => {
  const { formatMessage } = useIntl();
  if (config?.enableCloudScapeVisualRefresh === 'true') {
    return <TopNavigationBar />;
  } else {
    return (
      <header className={styles.wrapper}>
        <img
          className={styles.img}
          src={logo}
          alt={formatMessage(globalMessages.pageLogoAlt)}
        />
        <LocaleMenu />
      </header>
    );
  }
};

export default TopHeader;
