class UnauthorizedError extends Error {
  static code: string = 'UnauthorizedError';
  code: string;

  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype);
    this.code = UnauthorizedError.code;
  }
}

export default UnauthorizedError;
