import { IMPRESSIONS } from '../constants/metrics';
import configService from '../services/config';
import metricsService from '../services/metrics';

import logo from '../assets/logo-color-dark.svg';

/**
 * Checks if a browser meets our least common denominator in terms of browser features.
 * Note: To capture all possible cases, IE6/9 etc, this check should be done outside of
 * the app itself, either as separate script or in a lambda@edge. pre-processor
 */
export const isBrowserSupported = (
  windowContext = window,
  crypto = window.crypto ?? {}
) => {
  return Boolean(
    'localStorage' in windowContext &&
      'addEventListener' in windowContext &&
      'Promise' in windowContext &&
      'URLSearchParams' in windowContext &&
      'fetch' in windowContext &&
      'getRandomValues' in crypto
  );
};

/**
 * Renders a fallback page for non-supported browsers.
 */
export const renderUnupportedBrowser = async (element: HTMLElement) => {
  const mainStyle = [
    'max-width: 80rem',
    'margin: 2rem auto 0 auto',
    'padding: 2rem',
    'font-family: Amazon Ember, Arial, Times New Roman',
    'font-size: 1.4rem',
    'border-top: 1px solid #eaeded',
    'box-shadow: rgba(0, 28, 36, 0.3) 0px 1px 1px 0px, rgba(0, 28, 36, 0.15) 1px 1px 1px 0px, rgba(0, 28, 36, 0.15) -1px 1px 1px 0px',
  ];
  const linkStyle = 'color: #0073bb; text-decoration: none;';

  element.innerHTML = `
      <div style="${mainStyle.join(';')}">
        <img src="${logo}" alt="AWS Training and Certification" style="width: 19rem;" />
        <h1>This browser version isn’t supported for AWS Training and Certification</h1>
        <p>Use the latest version of the following supported browsers to sign in.</p>
        <ul>
          <li><a style="${linkStyle}" href="https://www.google.com/chrome/" rel="noopener noreferrer" target="_blank">Chrome</a></li>
          <li><a style="${linkStyle}" href="https://www.mozilla.org/firefox/browsers/" rel="noopener noreferrer" target="_blank">Firefox</a></li>
          <li><a style="${linkStyle}" href="https://www.microsoft.com/edge/" rel="noopener noreferrer" target="_blank">Edge</a></li>
          <li><a style="${linkStyle}" href="https://support.apple.com/downloads/safari" rel="noopener noreferrer" target="_blank">Safari (Mac only)</a></li>
        </ul>
      </div>
    `;
  document.body.appendChild(element);
  document.title = 'Unsupported browser';

  // Attempt to publish metrics
  try {
    const config = await configService.init();
    metricsService.init(config);
    const publisher = metricsService.getPublisher('NonSupportedBrowser');
    publisher.publishCounterMonitor(IMPRESSIONS, 1);
    publisher.publishStringTruncate('NonSupportedBrowser', navigator.userAgent);
  } catch (_) {
    // We tried
  }
};
