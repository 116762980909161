import { rumService } from '../services/rum';
import { IdPs } from '../constants/providers';

function getClientIdFromUrl(): string {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('client_id') || 'NA';
}

export function publishAuthMetrics(
  provider: IdPs,
  namespace: string,
  event: string
) {
  const clientId = getClientIdFromUrl();
  rumService.recordEvent(namespace, {
    IdP: provider,
    ClientId: clientId,
    event_name: event,
  });
}
