import styles from './ContainerLayout.module.css';
import { PostAuthFlows } from '../pages/AuthResponse';

const ContainerLayout = ({
  children,
  currentPostAuthFlow,
}: {
  children: React.ReactNode;
  currentPostAuthFlow?: PostAuthFlows;
}) => (
  <div
    className={
      currentPostAuthFlow === PostAuthFlows.ProfileAndPreferences
        ? styles.maxContainer
        : styles.container
    }
  >
    {children}
  </div>
);

export default ContainerLayout;
