import React from 'react';
import { LiveComponent } from '@amzn/aws-tc-xb-xps';

function PostPage() {
  return (
    <LiveComponent experienceProviderName="example" componentName="Posts" />
  );
}

export default PostPage;
