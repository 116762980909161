import { STAGE } from '../constants/xbosStage';
import { AppConfig } from '../types/app';
import { isDevelopment } from '../utils/env';
import { retry } from '../utils/promise';

const CONFIG_URI = '/settings.json';

let cachedConfig: AppConfig;
let isHcUATSignInValue = false;

const isHcUATSignIn = () => isHcUATSignInValue;

const HCUATCACHE = 'hcuatcache';
const init = async (): Promise<AppConfig> => {
  try {
    if (isDevelopment) {
      const response = await retry(() => import('../devconfig/settings.json'));
      return (cachedConfig = response.default);
    } else {
      const response = await retry(() => fetch(CONFIG_URI), {
        retries: 3,
        interval: 100,
      });
      cachedConfig = (await response.json()) as AppConfig;
      const clientIdFromUrl = new URLSearchParams(window.location.search).get(
        'client_id'
      );
      let clientId = clientIdFromUrl;
      if (
        clientId &&
        clientId !== cachedConfig.otpUserPoolClientId &&
        clientId !== cachedConfig.accountLinkAuthClientID
      ) {
        localStorage.removeItem(HCUATCACHE);
      } else {
        clientId = localStorage.getItem(HCUATCACHE);
        localStorage.removeItem(HCUATCACHE);
      }
      if (clientId && (cachedConfig.hcUatClients ?? []).includes(clientId)) {
        // eslint-disable-next-line no-console
        console.log('is hc uat client');
        isHcUATSignInValue = true;
        // Store the client id so that it can be retrieved during post-auth
        localStorage.setItem(HCUATCACHE, clientId);
        cachedConfig = {
          ...cachedConfig,
          xbosStage: STAGE.GAMMA,
          enableProfilePreferences: 'true',
          enableIdpTypeCheck: 'true',
          enableSkipPostAuthELTI: 'true',
          enableSignInSwitch: 'true',
          enableShowProfileDeactivatedScreen: 'true',
          enableRestricAccountLinking: 'true',
          show_invitation_screen: 'true',
          enableCloudScapeVisualRefresh: 'true',
          verificationEndpoint:
            'https://us-east-1.gamma.evs.auth.aws.training/VerifyEmail',
          gandalfProfileEndpoint:
            'https://8a24c1pxgc.execute-api.us-east-1.amazonaws.com',
          accountLinkAuthGandalfDomain: cachedConfig.gandalfDomain,
          accountLinkAuthUserPoolID: cachedConfig.authUserPoolId,
          accountLinkAuthClientID:
            cachedConfig.hcUatSigninClient || cachedConfig.authClientId,
          accountLinkValidateRegion: 'us-east-1',
          accountLinkingEndpoint:
            'https://us-east-1.gamma.gla.auth.aws.training',
          authClientId:
            cachedConfig.hcUatSigninClient || cachedConfig.authClientId,
        } as AppConfig;
      } else {
        isHcUATSignInValue = false;
      }
      return cachedConfig;
    }
  } catch (err: unknown) {
    throw new Error(`Failed to fetch config: ${(err as Error).message}`);
  }
};

const get = (): AppConfig => {
  if (!cachedConfig) throw new Error('Need to run init.');
  return cachedConfig;
};

const configService = {
  init,
  get,
  isHcUATSignIn,
};

export default configService;
