import { defineMessages } from 'react-intl';

// These help messages are created in pairs, question + answer, they have
// a shared key ID which ends with either `:question` or `:answer`.
//
// Example:
//
// 'helpID:question': {
//   id: 'newHelpContent.helpID.question',
//   defaultMessage: 'Who is awesome?',
// },
// 'helpID:answer': {
//   id: 'newHelpContent.helpID.answer',
//   defaultMessage: 'You are awesome.',
// }

export const signingInHelpMessages = defineMessages({
  'Whichsign-inmethodshouldIuse:question': {
    id: 'newHelpContent.Whichsign-inmethodshouldIuse.question',
    defaultMessage: 'Which sign-in method should I use?',
  },
  'Whichsign-inmethodshouldIuse:answer': {
    id: 'newHelpContent.Whichsign-inmethodshouldIuse.answer',
    defaultMessage: `We highly recommend that you use the same sign-in method to sign in to all AWS Training and Certification products so that you can see all your learning history in a single profile. Review the details for each option and choose the method that fits your situation:
          <h4>AWS Builder ID</h4>
          <builderID>AWS Builder ID</builderID> is a personal profile for everyone who builds on AWS. It is the recommended sign-in method for individuals using AWS Training and Certification products. Your AWS Builder ID is separate from any AWS account.Your AWS Builder ID represents you as an individual and complements any AWS accounts you may already own or want to create. Please see <awsBuilderIDAwsCred>AWS Builder ID and other AWS credentials</awsBuilderIDAwsCred> to learn how this is different from an AWS account.
          <b>Starting January 31, 2024, Login With Amazon is no longer supported for Training & Certification customers.</b>
        <b>Tip for existing customers who have used Login with Amazon to sign in to AWS Training and Certification before:</b>
        If you sign in with an AWS Builder ID that uses the same email address you’ve used to sign in with Login with Amazon, you’ll be prompted to link to link your AWS Builder ID to your existing profile.
        <h4>AWS Partner Network</h4>
        <apn>AWS Partner</apn> Network (APN) is recommended for employees of organizations registered as AWS partners.
        <h4>Organization SSO</h4>
        Organization SSO allows employees of enterprise customers to access AWS Training and Certification products using the same credentials that you use within your organization. With this option, you won’t need to create a separate profile in aws.training or skillbuilder.aws. You can also seamlessly interact between Skill Builder, AWS Training, and Classrooms without needing to re-authenticate.
        <h4>Amazon employee SSO</h4>
        Amazon employee SSO allows employees of Amazon to access AWS Training and Certification products using their work credentials.
          `,
  },
  'internetBrowserSupport:question': {
    id: 'newHelpContent.internetBrowserSupport.question',
    defaultMessage: 'Which internet browsers do the sign-in methods support?',
  },
  'internetBrowserSupport:answer': {
    id: 'newHelpContent.internetBrowserSupport.answer',
    defaultMessage: `The AWS Training and Certification experience works best on the 2 most recent major versions of:
          <ul>
            <li>Google Chrome </li>
            <li>Mozilla Firefox </li>
            <li>Microsoft Edge: </li>
            <li>Safari </li>
          </ul>`,
  },
  'signInWithAWS:question': {
    id: 'newHelpContent.signInWithAWS.question',
    defaultMessage: 'Can I sign in with my AWS account?',
  },
  'signInWithAWS:answer': {
    id: 'newHelpContent.signInWithAWS.answer',
    defaultMessage:
      'No, AWS account is not a sign-in method for AWS Training and Certification products.',
  },
  'reusePreviousSignInMethod:question': {
    id: 'newHelpContent.reusePreviousSignInMethod.question',
    defaultMessage:
      "I've signed in to other AWS Training and Certification experiences in the past. Can I use the same sign-in method?",
  },
  'reusePreviousSignInMethod:answer': {
    id: 'newHelpContent.reusePreviousSignInMethod.answer',
    defaultMessage:
      'Yes. You may use the same sign-in email and method you used before. We actually recommend that you do so you can access all your history and data in one profile.',
  },
  'resetPassword:question': {
    id: 'newHelpContent.resetPassword.question',
    defaultMessage: 'How do I reset my password?',
  },
  'resetPassword:answer': {
    id: 'newHelpContent.resetPassword.answer',
    defaultMessage: `Please follow the below instructions based on what sign-in method you use.
          <h4>AWS Builder ID</h4>
          Please visit <builderIDSignInIssue>AWS Builder ID</builderIDSignInIssue> for further assistance. 
          <h4>Organization SSO</h4>
          Reach out to your learning admin for assistance.
          <h4>AWS Partner</h4>
          Use the following link to create a support case with the Partner team: <hyperlink>https://www.apn-portal.com/knowledgebase/?cu=1&fs=ContactUs&l=en_US</hyperlink>
          `,
  },
  'iDontHaveAccess:question': {
    id: 'newHelpContent.iDontHaveAccess.question',
    defaultMessage: "I signed in but the page says I don't have access.",
  },
  'iDontHaveAccess:answer': {
    id: 'newHelpContent.iDontHaveAccess.answer',
    defaultMessage: `Some content may require you to have purchased a subscription before you are given access.

    If you already purchased a subscription and still do not have access to the expected content, make sure you are signed in using the same email and method that you used when you purchased the subscription.
    
    If your organization has a subscription, contact your learning administrator(s) and they may provide you with access and additional instructions.
    
    If you continue to have issues, please contact <supportLink>AWS Training and Certification customer support.</supportLink>`,
  },
});

export const accountLinkingHelpMessages = defineMessages({
  'WhatIsProfileLinking?:question': {
    id: 'newHelpContent.acountLinking.WhatIsProfileLinking',
    defaultMessage: 'What is profile linking?',
  },
  'WhatIsProfileLinking?:answer': {
    id: 'newHelpContent.accountLinking.WhatIsProfileLinking.answer',
    defaultMessage: `If you are an AWS Training and Certification customer <b>using the same email address</b> with a new sign-in method, you will be able to link your new sign-in method to your existing Training & Certification profile, if eligible. Eligibility depends on which sign-in methods are involved and is determined by business rules.`,
  },
  'IndividualLearnerNotSkillBuilder:question': {
    id: 'newHelpContent.acountLinking.IndividualLearnerNotSkillBuilder.question',
    defaultMessage:
      'I am an individual learner, not associated with an Organization subscription. What profile(s) are eligible for linking?',
    description: 'FAQ for individual learner not associated to SkillBuilder',
  },
  'IndividualLearnerNotSkillBuilder:answer': {
    id: 'newHelpContent.acountLinking.IndividualLearnerNotSkillBuilder.answer',
    defaultMessage: `Please see this chart on the eligibility rules. Data and activity will be available in both sign-in method(s) in this case. 
    <table>
        <tr>
            <th>Existing sign-in method</th>
            <th>New sign-in method</th>
            <th>Linking allowed (only if the same email address matches)</th>
        </tr>
        <tr>
            <td>Login with Amazon</td>
            <td>AWS Builder ID</td>
            <td>Yes</td>
        </tr>
        <tr>
            <td>Login with Amazon</td>
            <td>One-time email passcode</td>
            <td>Yes</td>
        </tr>
        <tr>
            <td>AWS Builder ID</td>
            <td>One-time email passcode</td>
            <td>Yes</td>
        </tr> 

    </table>`,
    description: 'Eligibility chart for the individual learner',
  },
  'ESSOWithSkillBuilderSubscription:question': {
    id: 'newHelpContent.accountLinking.ESSOWithSkillBuilderSubscription.question',
    defaultMessage:
      'I am an employee of an organization with an Organization subscription and have been advised to sign in using organization single sign-on (SSO). What profile(s) are eligible for linking?',
    description: 'FAQ for org SSO employee with Single Sign On',
  },
  'ESSOWithSkillBuilderSubscription:answer': {
    id: 'newHelpContent.accountLinking.ESSOWithSkillBuilderSubscription.answer',
    defaultMessage: `Please see this chart on the eligibility rules. Sign-in method(s) vary across AWS Training and Certification, so not all sign-in methods listed may be applicable. 
    <table>
        <tr>
            <th>Existing sign-in method(source)</th>
            <th>New sign-in method(destination)</th>
            <th>Linking allowed (Only if the same email address is used for both sign-in methods)</th>
        </tr>
        <tr>
            <td>Login with Amazon</td>
            <td>Organization SSO</td>
            <td>Yes. Data and activity will be transferred to the new sign-in method and the source sign-in method will appear as new.</td>
        </tr>
        <tr>
            <td>AWS Builder ID</td>
            <td>Organization SSO</td>
            <td>Yes. Data and activity will be transferred to the new sign-in method and the source sign-in method will appear as new.</td>
        </tr>
        <tr>
            <td>One-time email passcode</td>
            <td>Organization SSO</td>
            <td>Yes. Data and activity will be transferred to the new sign-in method and the source sign-in method will appear as new.</td>
        </tr>    
        <tr>
            <td>Organization SSO</td>
            <td>AWS Builder ID/Organization Single sign-on</td>
            <td>No</td>
        </tr>        
      </table>`,
    description: 'Org SSO eligibility charts',
  },
  'APNLinkingEligibility:question': {
    id: 'newHelpContent.accountLinking.APNLinkingEligibility.question',
    defaultMessage:
      'I am an employee of an organization set up for AWS Partner Network. What profile(s) are eligible for linking?',
    description: 'FAQ for APN eligibility',
  },
  'APNLinkingEligibility:answer': {
    id: 'newHelpContent.accountLinking.APNLinkingEligibility.answer',
    defaultMessage: `Please see this chart on the eligibility rules.  
    <table>
        <tr>
            <th>Existing sign-in method(source)</th>
            <th>New sign-in method(destination)</th>
            <th>Linking allowed (Only if the same email address is used for both sign-in methods)</th>
        </tr>
        <tr>
            <td>AWS Builder ID/ Organization SSO</td>
            <td>AWS Partner Network</td>
            <td>No</td>
        </tr>
        <tr>
            <td>AWS Partner Network</td>
            <td>AWS Builder ID </td>
            <td>No</td>
        </tr>
        <tr>
            <td>AWS Partner Network</td>
            <td>Organization SSO</td>
            <td>Yes. Data and activity will be transferred to the new sign-in method and the source sign-in method will appear as new.</td>
        </tr>         
      </table>`,
    description: 'FAQ for APN eligibility',
  },
  'OrgSSOTrailSubscription:question': {
    id: 'newHelpContent.accountLinking.OrgSSOTrailSubscription.question',
    defaultMessage:
      'During my organization’s trial subscription I used Login with Amazon or AWS Builder ID to sign in. Now I’m using organization SSO to sign in. Will I still be able to access my training activity and data by signing in with Login with Amazon or AWS Builder ID?',
    description: 'FAQ for Org SSO trail subscribers',
  },
  'OrgSSOTrailSubscription:answer': {
    id: 'newHelpContent.accountLinking.OrgSSOTrailSubscription.answer',
    defaultMessage:
      'No. Once you link an organization SSO sign-in method to your profile, your training activity and data are transferred to the new organization SSO sign-in method.',
    description: 'FAQ for Org ESSO trail customers',
  },
  'AmazonEmployeeEligibility:question': {
    id: 'newHelpContent.accountLinking.AmazonEmployeeEligibility.question',
    defaultMessage:
      'I am an internal Amazon employee. What profile(s) are eligible for linking? ',
    description: 'Amazon Employee FAQ rules',
  },
  'AmazonEmployeeEligibility:answer': {
    id: 'newHelpContent.accountLinking.AmazonEmployeeEligibility.answer',
    defaultMessage: `Please see this chart on the eligibility rules. Sign-in method(s) vary across AWS Training and Certification so not all sign-in methods listed may be applicable.
    <table>
        <tr>
            <th>Existing account(source)</th>
            <th>New account(destination)</th>
            <th>Linking allowed (Only if the same email address is used for both sign-in methods)</th>
        </tr>
        <tr>
            <td>Login with Amazon</td>
            <td>Amazon employee single sign-on (SSO)</td>
            <td>Yes. Data and activity will be transferred to the new sign-in method and the source sign-in method will appear as new.</td>
        </tr>
        <tr>
            <td>AWS Builder ID</td>
            <td>Amazon employee SSO</td>
            <td>Yes. Data and activity will be transferred to the new sign-in method and the source sign-in method will appear as new.</td>
        </tr>
        <tr>
            <td>One-time email passcode</td>
            <td>Amazon employee SSO</td>
            <td>Yes. Data and activity will be transferred to the new sign-in method and the source sign-in method will appear as new.</td>
        </tr>    
        <tr>
            <td>Amazon employee SSO</td>
            <td>Other sign-in methods</td>
            <td>No</td>
        </tr>        
      </table>`,
  },
  'MultipleProfilsWithSameEmail:question': {
    id: 'newHelpContent.accountLinking.MultipleProfilsWithSameEmail',
    defaultMessage:
      'I have multiple profiles associated with the same email address. Which profile will you link my new sign-in method to?',
  },
  'MultipleProfilsWithSameEmail:answer': {
    id: 'newHelpContent.accountLinking.MultipleProfilsWithSameEmail.answer',
    defaultMessage:
      'You can choose to link the new sign-in method to any of your existing eligible profile(s).',
  },
  'ChangedEmployer:question': {
    id: 'newHelpContent.accountLinking.ChangedEmployer',
    defaultMessage:
      'I changed employers (or I left my previous job), how do I make sure the new account has history from older account?',
  },
  'ChangedEmployer:answer': {
    id: 'newHelpContent.accountLinking.ChangedEmployer.answer',
    defaultMessage: `This isn't currently supported by the profile linking feature. Please contact <supportLink>AWS Training and Certification customer service</supportLink> for assistance.`,
  },
  'MovedLwaToBidFaq:question': {
    id: 'newHelpContent.accountLinking.MovedLwaToBidFaq.question',
    defaultMessage: `I moved from Login With Amazon to AWS Builder ID but can’t see my training history/certifications/subscriptions`,
  },
  'MovedLwaToBidFaq:answer': {
    id: 'newHelpContent.accountLinking.MovedLwaToBidFaq.answer',
    defaultMessage: `If you signed in with AWS Builder ID but are unable to see your training history, please contact AWS Training and Certification customer service at <supportLink>https://support.aws.amazon.com/#/contacts/aws-training</supportLink>. DO NOT delete your AWS Builder ID account.`,
  },
});

export const mergingHistory = defineMessages({
  'MergeHistory:question': {
    id: 'newHelpContent.mergeHistory.MergeHistory',
    defaultMessage:
      'I have multiple profiles with different sign-in methods. How do I merge my learning history?',
  },
  'MergeHistory:answer': {
    id: 'newHelpContent.mergeHistory.MergeHistory.answer',
    defaultMessage: `In some cases you may be able to merge your history from one or more profiles to a single destination profile. Please contact <supportLink>AWS Training and Certification customer service for assistance.</supportLink>`,
  },
});
