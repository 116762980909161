import { useCallback } from 'react';

import { AppConfig } from '../types/app';
import { AuthToken } from '../utils/authToken';
import logger from '../utils/logger';
import { retry } from '../utils/promise';
import { REQUIRE_EMAIL_VERIFICATION } from '../constants/auth';

export class EmailVerificationError extends Error {
  public response: Response;
  constructor(message: string, resp: Response) {
    super(message);
    this.response = resp;
  }
}

export class EmailVerificationClient {
  private emailVerificationEndpoint: string;

  constructor(config: AppConfig) {
    this.emailVerificationEndpoint = config.verificationEndpoint as string;
    if (!this.emailVerificationEndpoint?.includes(`/VerifyEmail`)) {
      this.emailVerificationEndpoint = `${this.emailVerificationEndpoint}/VerifyEmail`;
    }
  }

  public async verifyEmail(authToken: string, otpAuthToken: string) {
    const response = await retry(() =>
      fetch(`${this.emailVerificationEndpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          gandalf_token: authToken,
          otp_token: otpAuthToken,
        }),
      })
    );
    if (!response.ok) {
      throw new EmailVerificationError(
        'Email verification was not successful',
        response
      );
    }
  }
}

export const useEmailVerification = ({
  config,
  authToken,
  otpAuthToken,
  onVerificationComplete = () => {},
  onVerificationFail = () => {},
}: {
  config: AppConfig;
  authToken: AuthToken;
  otpAuthToken: string;
  onVerificationComplete?: () => void;
  onVerificationFail?: () => void;
}): (() => void) => {
  return useCallback(async () => {
    try {
      const client = new EmailVerificationClient(config);
      await client.verifyEmail(authToken.jwtToken, otpAuthToken);
      onVerificationComplete();
      const params = new URLSearchParams(authToken.originalRequestURL);
      params.delete(REQUIRE_EMAIL_VERIFICATION);
      window.open(
        `${window.location.protocol}//${
          window.location.host
        }/login?${params.toString()}`,
        '_self',
        'noopener,noreferrer'
      );
    } catch (error) {
      logger.error(error);
      onVerificationFail();
    }
  }, [
    config,
    authToken,
    otpAuthToken,
    onVerificationComplete,
    onVerificationFail,
  ]);
};
