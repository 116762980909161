import { ExclusivityType, GandalfUser } from '../types/auth';
import { AppConfig } from '../types/app';
import { useIntl } from 'react-intl';
import { IdPs } from '../constants/providers';
import React, { useState } from 'react';
import {
  Box,
  RadioGroup,
  SpaceBetween,
  TextContent,
} from '@amzn/awsui-components-react';
import { emitAccountLinkingRUMEvents } from '../services/emitAccountLinkingRUMEvent';
import accountlinkConsentMessages from '../i18n/accountlinkConsent.messages';
import { accountlinkfaqPageDetails } from '../i18n/accountLinkFaqContent.messages';
import { providerMessages } from '../i18n/provider.messages';
import { AppURL } from '../constants/urls';
import accountlinkConsent from '../i18n/accountlinkConsent.messages';
import { AuthToken } from '../utils/authToken';
import signinMessages from '../i18n/signin.messages';

interface radioGroupIdp {
  value: string;
  label: string;
}
const MultipleProfileLinkContent = ({
  user,
  config,
  authToken,
  setIdpSelected,
}: {
  user: GandalfUser;
  config: AppConfig;
  authToken: AuthToken;
  setIdpSelected: (idp: string) => void;
}): JSX.Element => {
  const [idp, setIdp] = useState('');
  const { formatMessage } = useIntl();
  if (user.associate_to) {
    const idpRadioGroup = (user: GandalfUser) => {
      let availableIdps: string[] | string = user.associate_to?.LinkedToIDP!;

      const uniqueIdps = Array.from(new Set(availableIdps));

      let radioGroupIdps: radioGroupIdp[] = [];

      for (var idp of uniqueIdps) {
        radioGroupIdps.push({
          value: idp,
          label: formatMessage(
            providerMessages[idp as IdPs]
              ? providerMessages[idp as IdPs]
              : signinMessages.organizationSigninButton
          ),
        });
      }

      return radioGroupIdps;
    };

    const matchingVibes = (user: GandalfUser): boolean => {
      const vibes: string[] | string = user.associate_to?.VibeId!;
      return new Set(vibes).size === 1;
    };
    return (
      <SpaceBetween direction="vertical" size="s">
        {user.associate_to.Exclusivity === ExclusivityType.INCLUSIVE ? (
          <>
            {!matchingVibes(user) ? (
              <>
                <TextContent>
                  {formatMessage(
                    accountlinkConsentMessages.multipleProfileLinkingWithOptionsContent,
                    {
                      originalUserEmail: user.email,
                      b: (str) => <b>{str}</b>,
                    }
                  )}
                </TextContent>
                <TextContent>
                  {formatMessage(accountlinkConsent.chooseSignInMethod)}
                </TextContent>
                <RadioGroup
                  onChange={({ detail }) => {
                    setIdpSelected(detail.value);
                    setIdp(detail.value);
                  }}
                  value={idp}
                  items={idpRadioGroup(user)}
                  data-testid="IDP_RADIO_GROUP"
                />
              </>
            ) : (
              <>
                <TextContent>
                  {formatMessage(
                    accountlinkConsentMessages.singleProfileLinkingWithOptionsContent,
                    {
                      originalUserEmail: user.email,
                      b: (str) => <b>{str}</b>,
                    }
                  )}
                </TextContent>
                <TextContent>
                  {formatMessage(accountlinkConsent.chooseSignInMethodSingle)}
                </TextContent>
                <RadioGroup
                  onChange={({ detail }) => {
                    setIdpSelected(detail.value);
                    setIdp(detail.value);
                  }}
                  value={idp}
                  items={idpRadioGroup(user)}
                  data-testid="IDP_RADIO_GROUP"
                />
              </>
            )}
          </>
        ) : (
          <>
            <TextContent>
              {formatMessage(
                accountlinkConsentMessages.multipleProfileExclusiveWithOptionsContent,
                {
                  currentUserEmail: user.email,
                  currentProvider: idp,
                }
              )}
            </TextContent>
            <TextContent>
              {formatMessage(accountlinkConsent.chooseSignInMethod)}
            </TextContent>
            <RadioGroup
              onChange={({ detail }) => {
                setIdpSelected(detail.value);
                setIdp(detail.value);
              }}
              value={idp}
              items={idpRadioGroup(user)}
            />
          </>
        )}

        <TextContent>
          <Box margin={{ top: 's' }} float="left">
            <a
              href={`${AppURL.AccountLinkFAQ}`}
              target="_blank"
              data-testid="account-link-help-link"
              rel="noopener noreferrer"
              onClick={() => {
                emitAccountLinkingRUMEvents(
                  authToken.jwtToken,
                  'confirm_exisiting_profile_learn_more_button'
                );
              }}
            >
              {formatMessage(
                accountlinkfaqPageDetails.learnMoreAboutProfileLinking
              )}
            </a>
          </Box>
        </TextContent>
      </SpaceBetween>
    );
  } else {
    throw new Error('SignIn Failed');
  }
};

export default MultipleProfileLinkContent;
