// BCP 47 language tag codes
export enum LocaleCode {
  ar_SA = 'ar-SA',
  en_US = 'en-US',
  fr_FR = 'fr-FR',
  de_DE = 'de-DE',
  el_GR = 'el-GR',
  he_IL = 'he-IL',
  id_ID = 'id-ID',
  it_IT = 'it-IT',
  ja_JP = 'ja-JP',
  ko_KR = 'ko-KR',
  pt_BR = 'pt-BR',
  zh_CN = 'zh-CN',
  es_ES = 'es-ES',
  es_419 = 'es-419',
  th_TH = 'th-TH',
  zh_TW = 'zh-TW',
  vi_VN = 'vi-VN',
}

export const DEFAULT_LOCALE = LocaleCode.en_US;

export enum TextDirection {
  RTL = 'rtl',
  LTR = 'ltr',
}

export type Locale = {
  id: LocaleCode;
  description: string;
  label: string;
  dir: TextDirection;
  lang: LocaleCode;
};

/**
 * All available locales.
 * This also represents the list order represented in the UI.
 */
export const LOCALES: { [key: string]: Locale } = {
  // RTL locales, like ar, are not yet supported. https://sim.amazon.com/issues/Vibe-485
  [LocaleCode.ar_SA]: {
    id: LocaleCode.ar_SA,
    description: 'Arabic',
    label: 'العربية',
    dir: TextDirection.RTL,
    lang: LocaleCode.ar_SA,
  },
  [LocaleCode.en_US]: {
    id: LocaleCode.en_US,
    description: 'English',
    label: 'English',
    dir: TextDirection.LTR,
    lang: LocaleCode.en_US,
  },
  [LocaleCode.fr_FR]: {
    id: LocaleCode.fr_FR,
    description: 'French',
    label: 'Français',
    dir: TextDirection.LTR,
    lang: LocaleCode.fr_FR,
  },
  [LocaleCode.de_DE]: {
    id: LocaleCode.de_DE,
    description: 'German',
    label: 'Deutsch',
    dir: TextDirection.LTR,
    lang: LocaleCode.de_DE,
  },
  [LocaleCode.el_GR]: {
    id: LocaleCode.el_GR,
    description: 'Greek',
    label: 'Ελληνικά',
    dir: TextDirection.LTR,
    lang: LocaleCode.el_GR,
  },
  // RTL locales, like he, are not yet supported. https://sim.amazon.com/issues/Vibe-485
  [LocaleCode.he_IL]: {
    id: LocaleCode.he_IL,
    description: 'Hebrew',
    label: 'עברית',
    dir: TextDirection.RTL,
    lang: LocaleCode.he_IL,
  },
  [LocaleCode.id_ID]: {
    id: LocaleCode.id_ID,
    description: 'Indonesian',
    label: 'Bahasa Indonesia',
    dir: TextDirection.LTR,
    lang: LocaleCode.id_ID,
  },
  [LocaleCode.it_IT]: {
    id: LocaleCode.it_IT,
    description: 'Italian',
    label: 'Italiano',
    dir: TextDirection.LTR,
    lang: LocaleCode.it_IT,
  },
  [LocaleCode.ja_JP]: {
    id: LocaleCode.ja_JP,
    description: 'Japanese',
    label: '日本語',
    dir: TextDirection.LTR,
    lang: LocaleCode.ja_JP,
  },
  [LocaleCode.ko_KR]: {
    id: LocaleCode.ko_KR,
    description: 'Korean',
    label: '한국어',
    dir: TextDirection.LTR,
    lang: LocaleCode.ko_KR,
  },
  [LocaleCode.pt_BR]: {
    id: LocaleCode.pt_BR,
    description: 'Portuguese (Brazil)',
    label: 'Português (Brasil)',
    dir: TextDirection.LTR,
    lang: LocaleCode.pt_BR,
  },
  [LocaleCode.zh_CN]: {
    id: LocaleCode.zh_CN,
    description: 'Simplified Chinese',
    label: '中文（简体）',
    dir: TextDirection.LTR,
    lang: LocaleCode.zh_CN,
  },
  [LocaleCode.es_ES]: {
    id: LocaleCode.es_ES,
    description: 'Spanish',
    label: 'Español',
    dir: TextDirection.LTR,
    lang: LocaleCode.es_ES,
  },
  [LocaleCode.es_419]: {
    id: LocaleCode.es_419,
    description: 'Spanish, Latin America',
    label: 'Español (Latinoamérica)',
    dir: TextDirection.LTR,
    lang: LocaleCode.es_419,
  },
  [LocaleCode.th_TH]: {
    id: LocaleCode.th_TH,
    description: 'Thai',
    label: 'ไทย',
    dir: TextDirection.LTR,
    lang: LocaleCode.th_TH,
  },
  [LocaleCode.zh_TW]: {
    id: LocaleCode.zh_TW,
    description: 'Traditional Chinese',
    label: '中文（繁體）',
    dir: TextDirection.LTR,
    lang: LocaleCode.zh_TW,
  },
  [LocaleCode.vi_VN]: {
    id: LocaleCode.vi_VN,
    description: 'Vietnames',
    label: 'Tiếng Việt',
    dir: TextDirection.LTR,
    lang: LocaleCode.vi_VN,
  },
};
