import CryptoJS from 'crypto-js';

import { IDP_URL_PARAM, REDIRECT_URI } from '../constants/auth';
import { localStorageAdapter } from '../services/storage';
import { AppConfig } from '../types/app';
import { IdPs } from '../constants/providers';
import { AppURL } from '../constants/urls';

export const LAST_USED_TOKEN = 'LAST_USED_TOKEN';
export const LAST_USED_GANDALF_TOKEN = 'LAST_USED_GANDALF_TOKEN';

export type AuthToken = {
  originalRequestURL: string;
  jwtToken: string;
  nonce: string;
};

export const marshalAuthToken = (state: AuthToken): string => {
  const jsonStringified = JSON.stringify(state);
  const encrypted = CryptoJS.AES.encrypt(jsonStringified, state.nonce);
  const base64 = btoa(encrypted.toString());

  return base64;
};

export const unmarshalAuthToken = (
  state: string | undefined,
  nonce: string | undefined
): AuthToken | null => {
  if (!state || !nonce) {
    return null;
  }

  try {
    const fromBase64 = atob(state);
    const value = CryptoJS.AES.decrypt(fromBase64, nonce).toString(
      CryptoJS.enc.Utf8
    );
    const jsonParsed = JSON.parse(value);

    if (jsonParsed.nonce !== nonce) {
      return null;
    }

    return jsonParsed as AuthToken;
  } catch (err) {
    return null;
  }
};

export const storeAuthToken = (authToken: AuthToken) => {
  const marshaledAuthToken = marshalAuthToken(authToken);
  localStorageAdapter.setItem(LAST_USED_TOKEN, marshaledAuthToken);
};

export const deriveIdpUrl = (
  config: AppConfig,
  idp: string,
  nonce: string,
  redirect_uri: string
) => {
  const urlParams = new URLSearchParams();
  urlParams.set(IDP_URL_PARAM, idp);
  urlParams.set(REDIRECT_URI, redirect_uri);
  urlParams.set('client_id', config.authClientId);
  urlParams.set('response_type', 'code');
  urlParams.set('state', nonce);

  const baseUrl = `https://${config.gandalfDomain}/oauth2/authorize`;
  const idpUrl = `${baseUrl}?${urlParams.toString()}`;

  return { url: idpUrl, params: urlParams };
};

export const deriveOtpUrl = (
  config: AppConfig,
  nonce: string,
  redirect_uri: string
) => {
  const urlParams = new URLSearchParams();
  urlParams.set(IDP_URL_PARAM, IdPs.OTP);
  urlParams.set(REDIRECT_URI, redirect_uri);
  urlParams.set('client_id', config.otpUserPoolClientId as string);
  urlParams.set('state', nonce);

  return `${window.location.origin}${
    AppURL.SendVerificationEmail
  }?${urlParams.toString()}`;
};
