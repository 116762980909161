import { defineMessages } from 'react-intl';

const emailVerificationMessages = defineMessages({
  header: {
    id: 'emailVerification.header',
    defaultMessage: 'Verify your email to continue sign in',
    description: 'Header of the email verification page.',
  },
  description: {
    id: 'emailVerification.description',
    defaultMessage:
      'We sent an email containing a one-time passcode to {userEmail}. You should receive it within 5 minutes.',
    description: 'Email verification description.',
  },
});

export default emailVerificationMessages;
