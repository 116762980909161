import { API } from '@aws-amplify/api';
import Auth from '@aws-amplify/auth';
import { MemoryStorage } from '@aws-amplify/core';
import { Sha256 } from '@aws-crypto/sha256-browser';
import { CognitoUser } from 'amazon-cognito-identity-js';
import { AppConfig } from '../types/app';
import { UserAndAuthStatus } from '../shared/userAndAuthStatus.interface';

export const init = (config: AppConfig) => {
  Auth.configure({
    Auth: {
      storage: MemoryStorage,
      region: config.region,
      userPoolId: config.otpUserPoolId,
      userPoolWebClientId: config.otpUserPoolClientId,
    },
  });
};

export const startSession = async (email: string) => {
  const username = await emailToUsername(email);
  const cognitoUser: CognitoUser = await Auth.signIn(username, undefined);
  return cognitoUser;
};

export const newUser = async (email: string) => {
  const username = await emailToUsername(email);
  const code = 'dummy';

  // NOTE: Configuring via Amplify.configure() does not seem to work - results in "API does not exist" error.  So, we
  // use the modular approach.

  try {
    await Auth.confirmSignUp(username, code, { forceAliasCreation: false });
  } catch (err: any) {
    switch (err.code) {
      case 'UserNotFoundException':
        return true;
      case 'NotAuthorizedException':
        return false;
      case 'AliasExistsException':
        // Email alias already exists
        return false;
      case 'CodeMismatchException':
        return false;
      case 'ExpiredCodeException':
        return false;
      default:
        return false;
    }
  }
};

export const signUp = async (email: string) => {
  const username = await emailToUsername(email);
  const params = {
    username: username,
    password: getRandomString(30),
    attributes: {
      email: email,
      name: email.split('@')[0],
    },
  };
  await Auth.signUp(params);
};

const emailToUsername = async (email: string) => {
  const hash = new Sha256();
  hash.update(email.trim().toLowerCase());
  const bytes = await hash.digest();

  // ref: https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
  const hashArray = Array.from(bytes);
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, '0'))
    .join(''); // convert bytes to hex string

  return hashHex;
};

const getRandomString = (bytes: number) => {
  const randomValues = new Uint8Array(bytes);
  window.crypto.getRandomValues(randomValues);
  return Array.from(randomValues).map(intToHex).join('');
};

const intToHex = (nr: number) => {
  return nr.toString(16).padStart(2, '0');
};

// We are pretending to send an answer to authorize but intention is to keep the session active
export const keepSessionOpen = async (cognitoUser: CognitoUser | undefined) => {
  cognitoUser = await Auth.sendCustomChallengeAnswer(cognitoUser, 'X', {
    extendSession: 'true',
  });
  return cognitoUser;
};

// After signing in and exchanging tokens for an OIDC auth code, we don't need the session or tokens anymore - we should
// invalidate them with GlobalSignOut.
// Ref: https://docs.aws.amazon.com/cognito/latest/developerguide/amazon-cognito-user-pools-using-tokens-with-identity-providers.html#amazon-cognito-identity-user-pools-revoking-all-tokens-for-user
export const globalSignOut = async () => {
  try {
    await Auth.signOut({ global: true });
  } catch (err) {
    throw err;
  }
};

export const answerCustomChallenge = async (
  answer: string,
  cognitoUser: CognitoUser | undefined
) => {
  const rtnCognitoUser: CognitoUser = await Auth.sendCustomChallengeAnswer(
    cognitoUser,
    answer
  );
  const userAndAuthStatus: UserAndAuthStatus = {
    cognitoUser: rtnCognitoUser,
    isAuthenticated:
      (await isAuthenticated()) &&
      Boolean(rtnCognitoUser.getSignInUserSession()),
  };
  return userAndAuthStatus;
};

export const answerLocaleChallenge = async (
  locale: string,
  cognitoUser: CognitoUser | undefined
) => {
  const metaData = {
    locale,
  };
  const rtnCognitoUser: CognitoUser = await Auth.sendCustomChallengeAnswer(
    cognitoUser,
    locale,
    metaData
  );
  const rtnObject: UserAndAuthStatus = {
    cognitoUser: rtnCognitoUser,
    isAuthenticated:
      (await isAuthenticated()) &&
      Boolean(rtnCognitoUser.getSignInUserSession()),
  };
  return rtnObject;
};

const isAuthenticated = async () => {
  try {
    await Auth.currentSession();
    return true;
  } catch {
    return false;
  }
};

export const getUserDetails = async (cognitoUser: CognitoUser | undefined) => {
  if (!cognitoUser) {
    cognitoUser = await Auth.currentAuthenticatedUser();
  }
  return await Auth.userAttributes(cognitoUser);
};

export const createCognitoUser = async (username: string, session: string) => {
  try {
    const user: CognitoUser = await (Auth as any).createCognitoUser(username);
    (user as any).Session = session;
    return user;
  } catch (err) {
    throw err;
  }
};

export const getResponseUrl = async (
  config: AppConfig,
  idToken: string,
  accessToken: string,
  redirectUri: string,
  state: string
) => {
  const apiName = config.otpOidcApiServiceName;

  // ref: https://docs.amplify.aws/lib/restapi/getting-started/q/platform/js#configure-your-application
  const amplifyConfig = {
    Auth: {
      storage: MemoryStorage,
      identityPoolId: config.otpIdentityPoolId,
      region: config.region,
    },
    API: {
      endpoints: [
        {
          name: apiName,
          endpoint: config.otpOidcApiUrl,
        },
      ],
    },
  };

  // NOTE: Configuring via Amplify.configure() does not seem to work - results in "API does not exist" error.  So, we
  // use the modular approach.
  Auth.configure(amplifyConfig);
  API.configure(amplifyConfig);

  // ref: https://docs.amplify.aws/lib/restapi/update/q/platform/js
  const path = '/getResponseUrl';
  const request = {
    body: {
      idToken: idToken,
      accessToken: accessToken,
      redirectUri: redirectUri,
      state: state,
    },
  };

  return await API.post(apiName, path, request).then((response) => {
    return response.responseUrl;
  });
};
