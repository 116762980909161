import jwtDecode from 'jwt-decode';
import { rumService } from './rum';
import { ExclusivityType } from '../types/auth';
import { DEFAULT_LOCALE } from '../constants/locales';
import { getInitialLocaleSelection } from '../contexts/LocaleContextProvider';

export function emitAccountLinkingRUMEvents(
  authToken: string,
  eventName: string
) {
  const authTokenDecoded: any = jwtDecode(authToken);
  const originalAuthAssociateTo = JSON.parse(authTokenDecoded['associate_to']);
  const urlSearchParams = new URLSearchParams(window.location.search);
  const clientidUsed = urlSearchParams.get('client_id');
  const redirectUriUsed = urlSearchParams.get('redirect_uri');
  rumService.recordEvent(eventName, {
    sourceIdP: originalAuthAssociateTo.LinkedToIDP,
    destinationIdP: authTokenDecoded.public_provider_name,
    isExlusive:
      originalAuthAssociateTo.Exclusivity === ExclusivityType.EXCLUSIVE,
    isMultiAccount: originalAuthAssociateTo.MultipleMatches,
    clientId: clientidUsed,
    redirect_uri: redirectUriUsed,
    emailDomain: getEmailDomain(authTokenDecoded.email),
    newUser: authTokenDecoded.new_user,
    languageSelected: getInitialLocaleSelection(DEFAULT_LOCALE),
    vibeID: originalAuthAssociateTo.VibeId,
  });
}

const getEmailDomain = (emailValue: string) => {
  let [, emailDomain] = emailValue.split('@');
  if (!emailDomain) return;
  emailDomain = emailDomain.toLowerCase();
  return emailDomain;
};
