import React from 'react';
import { Box, SpaceBetween, TextContent } from '@amzn/awsui-components-react';

import { ExclusivityType, GandalfUser } from '../types/auth';

import { IdPs } from '../constants/providers';
import { AppURL } from '../constants/urls';
import { AppConfig } from '../types/app';
import { useIntl } from 'react-intl';
import accountlinkConsentMessages from '../i18n/accountlinkConsent.messages';
import { accountlinkfaqPageDetails } from '../i18n/accountLinkFaqContent.messages';
import signinMessages from '../i18n/signin.messages';
import accountlinkConsent from '../i18n/accountlinkConsent.messages';
import { providerMessages } from '../i18n/provider.messages';
import { format } from 'date-fns';
import { emitAccountLinkingRUMEvents } from '../services/emitAccountLinkingRUMEvent';
import { AuthToken } from '../utils/authToken';

const SingleProfileAccountLink = ({
  user,
  config,
  authToken,
}: {
  user: GandalfUser;
  config: AppConfig;
  authToken: AuthToken;
}): JSX.Element => {
  const { formatMessage } = useIntl();
  let associateIdpTo: string[] | string | undefined;
  let associateIdpToDate: string;

  if (user.associate_to) {
    associateIdpTo =
      typeof user.associate_to.LinkedToIDP === 'string'
        ? user.associate_to.LinkedToIDP
        : user.associate_to.LinkedToIDP[0];
    associateIdpToDate = Array.isArray(user.associate_to.LinkedToLastSignedIn)
      ? user.associate_to.LinkedToLastSignedIn[0]
      : user.associate_to.LinkedToLastSignedIn;
    return (
      <SpaceBetween direction="vertical" size="s">
        {user.associate_to.Exclusivity === ExclusivityType.INCLUSIVE ? (
          <>
            <TextContent>
              {formatMessage(
                accountlinkConsentMessages.linkingMultipleAccounts,
                {
                  userEmail: user.email,
                  currentProviderName:
                    providerMessages[associateIdpTo as IdPs]?.defaultMessage ||
                    formatMessage(signinMessages.organizationSigninButton),
                  lastUsedDate: format(
                    new Date(associateIdpToDate),
                    'MMMM d, yyyy'
                  ),
                  newProviderName:
                    providerMessages[user.providerName as IdPs]
                      ?.defaultMessage ||
                    formatMessage(signinMessages.organizationSigninButton),
                  b: (str) => <b>{str}</b>,
                }
              )}
            </TextContent>
            <TextContent>
              {formatMessage(
                accountlinkConsent.confirmExistingProfileOwnershipMessage,
                {
                  userEmail: user.email,
                  currentProviderName:
                    providerMessages[associateIdpTo as IdPs]?.defaultMessage ||
                    formatMessage(signinMessages.organizationSigninButton),
                  b: (str) => <b>{str}</b>,
                }
              )}
            </TextContent>
          </>
        ) : (
          <>
            <TextContent>
              {formatMessage(
                accountlinkConsentMessages.linkingExclusiveSignleAccount,
                {
                  userEmail: user.email,
                  currentProviderName:
                    providerMessages[associateIdpTo as IdPs]?.defaultMessage ||
                    formatMessage(signinMessages.organizationSigninButton),
                  newProviderName:
                    providerMessages[user.providerName as IdPs]
                      ?.defaultMessage ||
                    formatMessage(signinMessages.organizationSigninButton),
                  b: (str) => <b>{str}</b>,
                }
              )}
            </TextContent>
            <TextContent>
              {formatMessage(
                accountlinkConsent.confirmExistingProfileOwnershipExclusiveMessage,
                {
                  userEmail: user.email,
                  currentProviderName:
                    providerMessages[associateIdpTo as IdPs]?.defaultMessage ||
                    formatMessage(signinMessages.organizationSigninButton),
                  b: (str) => <b>{str}</b>,
                }
              )}
            </TextContent>
          </>
        )}

        <TextContent>
          <Box margin={{ top: 's' }} float="left">
            <a
              href={`${AppURL.AccountLinkFAQ}`}
              target="_blank"
              data-testid="account-link-help-link"
              rel="noopener noreferrer"
              onClick={() => {
                emitAccountLinkingRUMEvents(
                  authToken.jwtToken,
                  'confirm_exisiting_profile_learn_more_button'
                );
              }}
            >
              {formatMessage(
                accountlinkfaqPageDetails.learnMoreAboutProfileLinking
              )}
            </a>
          </Box>
        </TextContent>
      </SpaceBetween>
    );
  } else {
    throw new Error('SignIn Failed');
  }
};

export default SingleProfileAccountLink;
