import { useEffect } from 'react';

import { setAmplifyConfig } from '../authConfig/amplify';
import { AppConfig } from '../types/app';
import { IdProvider } from '../types/auth';

export function useAmplifyConfig(config: AppConfig, idp?: IdProvider) {
  useEffect(() => {
    setAmplifyConfig(config, idp);
  }, [config, idp]);
}

export function useExplicitAmplifyConfig(config: AppConfig, idp?: IdProvider) {
  useEffect(() => {
    if (!idp) {
      return;
    }

    setAmplifyConfig(config, idp);
  }, [config, idp]);
}
