import { createContext, useContext } from 'react';

export interface StateStorage {
  setItem(key: string, value: string): void;
  getItem(key: string): string | null;
  removeItem(key: string): void;
}

export const noopStorage: StateStorage = {
  setItem(key, value) {
    // noop
  },
  getItem(key) {
    return null;
  },
  removeItem(key) {
    // noop
  },
};

export class MemoryStorage implements StateStorage {
  data: Partial<Record<string, string>> = {};
  setItem(key: string, value: string): void {
    this.data[key] = value;
  }
  getItem(key: string): string | null {
    return this.data[key] ?? null;
  }
  removeItem(key: string): void {
    delete this.data[key];
  }
}

export class TransformKeyStorage implements StateStorage {
  constructor(
    readonly storage: StateStorage,
    readonly transformKey: (key: string) => string
  ) {}
  setItem(key: string, value: string): void {
    this.storage.setItem(this.transformKey(key), value);
  }
  getItem(key: string): string | null {
    return this.storage.getItem(this.transformKey(key));
  }
  removeItem(key: string): void {
    this.storage.removeItem(this.transformKey(key));
  }
}

export const StateStorageContext = createContext<StateStorage>(noopStorage);

export const StateStorageContextProvider = StateStorageContext.Provider;

export function useStateStorage() {
  return useContext(StateStorageContext);
}
