import { GandalfUser, PostAuthComponentParams } from '../../types/auth';
import { useEffect } from 'react';
import { AppConfig } from '../../types/app';
import { redirectToLXPAuth } from '../../services/auth';
import providersService from '../../services/providers';
import { IDP_ELTI } from '../../constants/providers';

const SkipPostAuthElti = ({
  user,
  onContinue,
  onCancel,
  config,
  idpDetails,
}: PostAuthComponentParams): JSX.Element => {
  useEffect(() => {
    if (config.enableSkipPostAuthELTI !== 'true') {
      onContinue();
      return;
    }
    const checkIsELtiIdp = async (user: GandalfUser, config: AppConfig) => {
      if (idpDetails && idpDetails.idpType === IDP_ELTI) {
        redirectToLXPAuth(
          providersService.transformNameToIdProvider(
            user.providerName,
            config.gandalfDomain
          )
        );
      } else {
        onContinue();
      }
    };
    checkIsELtiIdp(user, config);
    // eslint-disable-next-line
  }, []);

  return <></>;
};

export default SkipPostAuthElti;
