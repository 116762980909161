import { useEffect } from 'react';

import { useLocaleContext } from '../contexts/LocaleContextProvider';

const useHtmlLocale = () => {
  const { locale } = useLocaleContext();
  useEffect(() => {
    document.documentElement.setAttribute('lang', locale);
  }, [locale]);
};

export default useHtmlLocale;
