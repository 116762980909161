import { useEffect, useState } from 'react';
import { useLinkndRedirect } from '../hooks/useAccountLInkRedirect';
import { AppConfig } from '../types/app';
import { AuthToken } from '../utils/authToken';
import { Icon, Spinner, TextContent } from '@amzn/awsui-components-react';
import AccountLinkFailed from '../pages/AccountLinkFailed';
import accountlinkConsentMessages from '../i18n/accountlinkConsent.messages';
import { useIntl } from 'react-intl';
import { emitAccountLinkingRUMEvents } from '../services/emitAccountLinkingRUMEvent';

export const LinkAccounts = ({
  config,
  at2Token,
  changeSpinnerState,
  changeLinkingSuccess,
  validationToken,
  isExclusive,
}: {
  config: AppConfig;
  at2Token: AuthToken;
  changeSpinnerState?: any;
  validationToken?: string;
  changeLinkingSuccess?: any;
  isExclusive?: boolean | null;
}) => {
  const [failedAccountLink, setFailedAccountLink] = useState<boolean>(false);
  const [accountlinkError, setAccountlinkError] = useState<any | null>(null);
  const [linkingSuccess, setLinkingSuccess] = useState<boolean>(false);
  const { formatMessage } = useIntl();

  const linkAccounts = useLinkndRedirect({
    at2Token,
    config,
    validationToken,
    onLinkComplete: () => {
      emitAccountLinkingRUMEvents(at2Token.jwtToken, 'account_link_complete');
      setLinkingSuccess(true);
    },
    onAccountLinkFail: (e) => {
      setFailedAccountLink(true);
      setAccountlinkError(e);
    },
  });

  useEffect(() => {
    if (at2Token && config) {
      try {
        emitAccountLinkingRUMEvents(
          at2Token.jwtToken,
          'account_link_in_progress'
        );
        linkAccounts();
      } catch (e) {
        setFailedAccountLink(true);
        setAccountlinkError(e);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {failedAccountLink ? (
        <AccountLinkFailed
          error={accountlinkError}
          authToken={at2Token.jwtToken}
          config={config}
        />
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {linkingSuccess ? (
              <Icon name="check" size="large" />
            ) : (
              <Spinner size="large" />
            )}
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <TextContent>
              <h4>
                {!isExclusive
                  ? formatMessage(
                      accountlinkConsentMessages.linkingNewSignInMethodLoadingMessage
                    )
                  : formatMessage(
                      accountlinkConsentMessages.transferNewSignInMethodLoadingMessage
                    )}
              </h4>
            </TextContent>
          </div>
        </>
      )}
    </>
  );
};
