import { MessageDescriptor } from 'react-intl';
import { IdPs } from '../constants/providers';
import { providerMessages } from '../i18n/provider.messages';
import signinMessages from '../i18n/signin.messages';

export const renderProviderName = (
  idp: IdPs,
  formatMessage: (message: MessageDescriptor) => string
) => {
  return (
    providerMessages[idp]?.defaultMessage ||
    formatMessage(signinMessages.organizationSigninButton)
  );
};
