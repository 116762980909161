import { defineMessages } from 'react-intl';

const topNavigationMessages = defineMessages({
  title: {
    id: 'TopNavigation.title',
    defaultMessage: 'Training and Certification',
  },
});

export default topNavigationMessages;
