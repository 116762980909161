import React, { useEffect, useState } from 'react';
import ContainerLayout from './ContainerLayout';
import {
  Button,
  Container,
  Form,
  Header,
  SpaceBetween,
  TextContent,
} from '@amzn/awsui-components-react';
import { useIntl } from 'react-intl';
import styles from './CardContainer.module.css';
import lwaSunsetMessages from '../i18n/lwaSunsetPhase.messages';
import lwaSunsetPhaseMessages from '../i18n/lwaSunsetPhase.messages';
import { useStateStorage } from '../contexts/StateStorageContextProvider';
import { useNonceGenerator } from '../contexts/NonceGeneratorContextProvider';
import { startAuthFlow } from '../services/auth';
import { IdPs } from '../constants/providers';
import { IdProvider, PostAuthComponentParams } from '../types/auth';
import providersService from '../services/providers';
import { IDP_URL_PARAM } from '../constants/auth';
import { publishAuthMetrics } from '../utils/publishLwaSunsetMetrics';
import { useAmplifyConfig } from '../hooks/useAmplifyConfig';

const LwaNewUserPrevention = ({
  user,
  onContinue,
  onCancel,
  config,
  error,
}: PostAuthComponentParams): JSX.Element => {
  const { formatMessage } = useIntl();

  const bidProvider: IdProvider = providersService.transformNameToIdProvider(
    IdPs.AWSBuilderID,
    config.gandalfDomain
  );

  useAmplifyConfig(config);

  const stateStorage = useStateStorage();

  const nonceGenerator = useNonceGenerator();

  const [renderLwaPrevention, setRenderLwaPrevention] =
    useState<boolean>(false);
  function initiateBuilderIDAuth() {
    publishAuthMetrics(
      IdPs.LoginWithAmazon,
      'LwaPostAuthPrevention',
      'InitiateBuilderID'
    );
    startAuthFlow({
      provider: bidProvider,
      config,
      storage: stateStorage,
      nonceGenerator,
    });
  }

  function chooseAnotherMethod() {
    publishAuthMetrics(
      IdPs.LoginWithAmazon,
      'LwaPostAuthPrevention',
      'ChooseAnotherMethod'
    );
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete(IDP_URL_PARAM);
    window.open(
      `${window.location.protocol}//${
        window.location.host
      }/login?${urlParams.toString()}`,
      '_self',
      'noopener,noreferrer'
    );
  }

  const preventLwaUser = Boolean(config.preventLwaUser === 'true');

  useEffect(() => {
    if (
      user.providerName !== IdPs.LoginWithAmazon ||
      preventLwaUser === false
    ) {
      onContinue();
    } else if (
      preventLwaUser &&
      user.created_on &&
      user?.created_on > new Date(config.lwaSunsetDate).getTime()
    ) {
      setRenderLwaPrevention(true);
    } else {
      publishAuthMetrics(
        IdPs.LoginWithAmazon,
        'LwaPostAuthPrevention',
        'OldUser'
      );
      onContinue();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (renderLwaPrevention) {
      publishAuthMetrics(
        IdPs.LoginWithAmazon,
        'LwaPostAuthPrevention',
        'Page_Impression'
      );
    }
  }, [renderLwaPrevention]);

  return (
    <>
      {renderLwaPrevention && user.providerName === IdPs.LoginWithAmazon ? (
        <ContainerLayout>
          <Container
            header={
              <Header
                className={styles.header}
                variant="h2"
                headingTagOverride="h1"
              >
                {formatMessage(lwaSunsetPhaseMessages.chooseAnotherMethod)}
              </Header>
            }
          >
            <Form
              actions={
                <>
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button
                      data-testid="continue"
                      onClick={(e) => {
                        e.preventDefault();
                        chooseAnotherMethod();
                      }}
                    >
                      {formatMessage(
                        lwaSunsetMessages.chooseAnotherMethodButton
                      )}
                    </Button>
                    <Button
                      variant="primary"
                      data-testid="sign-in-builder-id"
                      onClick={(e) => {
                        e.preventDefault();
                        initiateBuilderIDAuth();
                      }}
                    >
                      {formatMessage(
                        lwaSunsetMessages.signInWithBuilderIDButton
                      )}
                    </Button>
                  </SpaceBetween>
                </>
              }
            >
              <TextContent>
                {formatMessage(lwaSunsetMessages.lwaSunsetPostAuthContent)}
              </TextContent>
            </Form>
          </Container>
        </ContainerLayout>
      ) : null}
    </>
  );
};

export default LwaNewUserPrevention;
