import fetch from 'isomorphic-fetch';

import metricsService from '../services/metrics';
import logger from './logger';
import { retry } from './promise';

/**
 * API wrapper which emits metrics and applies a retry strategy.
 */
export const request = async (
  url: string,
  fetchOpts: RequestInit = {},
  {
    metricNs,
    treat404AsOk = false,
    treat400AsOk = false,
  }: {
    metricNs: string;
    // TODO: To enable more precise request error handling more of the fetch meta
    // data should be returned in both happy- and error cases. This will enable
    // status code inpection. Another valid path would also be to swap out this
    // wrapper to npm package, ex Axios.
    treat404AsOk?: boolean;
    //This is required for getting error on the Consent request where 400 error code could mean
    // that email is not verified
    treat400AsOk?: boolean;
  }
): Promise<Response> => {
  const publisher = metricsService.getPublisher(metricNs);
  const totalTimer = metricsService
    .createTimerStopWatch(`${metricNs}Time`)
    .withMonitor();
  return await retry(
    () => {
      const requestTimer = metricsService
        .createTimerStopWatch(`${metricNs}RequestTime`)
        .withMonitor();
      return fetch(url, fetchOpts)
        .then((resp: Response) => {
          const is404Ok = resp.status === 404 && treat404AsOk;
          const is400Ok = resp.status === 400 && treat400AsOk;
          if (resp.ok || is404Ok || is400Ok) {
            publisher.publishCounterMonitor(`${metricNs}Success`, 1);
            return resp;
          }

          logger.error(`Endpoint [${url}] returns an error response: ${resp}`);
          publisher.publishStringTruncate(
            `${metricNs}ErrorCode`,
            `${resp.status}`
          );
          throw new Error(`${resp.status}: ${resp.statusText}`);
        })
        .catch((error: Error) => {
          logger.error('Encountered an expected error:', error);
          publisher.publishCounterMonitor(`${metricNs}RequestError`, 1);
          publisher.publishStringTruncate(
            `${metricNs}ErrorMsg`,
            error.message || 'NA'
          );
          publisher.publishStringTruncate(
            `${metricNs}ErrorUri`,
            `${window.location.pathname}${window.location.search}`
          );
          publisher.publishStringTruncate(
            `${metricNs}UserAgent`,
            navigator.userAgent
          );
          throw error;
        })
        .finally(() => {
          publisher.publish(requestTimer);
        });
    },
    {
      retries: 3,
      interval: 100,
    }
  )
    .catch((error: Error) => {
      // Captures when an error is impacting the customer experience, after
      // retries are exhausted.
      publisher.publishCounterMonitor(`${metricNs}Error`, 1);
      throw error;
    })
    .finally(() => {
      publisher.publish(totalTimer);
    });
};

// This function is helpful to join endpoint and API path
export function joinUrlPath(a: string, b: string) {
  return `${a.replace(/\/+$/, '')}/${b.replace(/^\/+/, '')}`;
}
