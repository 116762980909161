import Cookies, { CookieAttributes, CookiesStatic } from 'js-cookie';
import { StateStorage } from '../contexts/StateStorageContextProvider';

export class CookieStorage implements StateStorage {
  readonly cookies: CookiesStatic;
  readonly options: CookieAttributes;
  constructor({
    domain = window.location.hostname,
    secure = window.location.protocol !== 'http:',
    sameSite = 'strict',
    ...defaults
  }: CookieAttributes = {}) {
    this.cookies = Cookies;
    this.options = { domain, secure, ...defaults };
  }
  setItem(key: string, value: string): void {
    this.cookies.set(key, value, this.options);
  }
  getItem(key: string): string | null {
    return this.cookies.get(key) ?? null;
  }
  removeItem(key: string): void {
    this.cookies.remove(key, this.options);
  }
}
