import TextContent from '@amzn/awsui-components-react/polaris/text-content';
import Box from '@amzn/awsui-components-react/polaris/box';
import { useIntl } from 'react-intl';

import metricsService from '../services/metrics';
import globalMessages from '../i18n/global.messages';
import { AppURL } from '../constants/urls';

const HelpLink = () => {
  const { formatMessage } = useIntl();
  const publishClickMetric = () => {
    const metricsPublisher = metricsService.getPublisher('Help');
    metricsPublisher.publishCounterMonitor('LinkClick', 1);
  };

  return (
    <TextContent>
      <Box margin={{ top: 's' }} float="right">
        <a
          href={AppURL.Help}
          target="_blank"
          onClick={publishClickMetric}
          data-testid="help-link"
          rel="noopener noreferrer"
        >
          {formatMessage(globalMessages.helpLink)}
        </a>
      </Box>
    </TextContent>
  );
};

export default HelpLink;
