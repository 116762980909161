/**
 * API path to get or update Gandalf profile vias attributes
 */
export const VIBE_ATTR_PATH = '/vibeAttributes';

/**
 * API path to update user consent
 */
export const CONSENT_PATH = '/consent';

/**
 * API path to perform email lookup on the IDP API
 * https://tiny.amazon.com/mdc12hrt
 */
export const EMAIL_LOOKUP_PATH = '/domain';

/**
 * API path to perform app client lookup
 * https://tiny.amazon.com/zsbn9il0
 */
export const APP_CLIENT_LOOKUP_PATH = '/appclient';

/**
 * API path to get public IDP details
 * https://tiny.amazon.com/17tco2ahj
 */
export const GET_PUBLIC_IDP_DETAILS_PATH = '/idp';

/**
 * The attributes labeled differently in the webapp vs backend. This mapping would sync up those labels
 * @constant {Map}
 */
export const FE_TO_BE_ATTR_MAPPING = new Map(
  Object.entries({
    name: 'fullName',
    email: 'notificationEmail',
    EULA: 'EULAStatus',
    recoveryAccounts: 'recoveryAccounts',
    needsVerification: 'needsVerification',
    userMergeConsentRequired: 'userMergeConsentRequired',
  })
);

/**
 * Reversed map of the above
 * @constant {Map}
 */
export const BE_TO_FE_ATTR_MAPPING = new Map(
  Array.from(FE_TO_BE_ATTR_MAPPING, (entry) => [entry[1], entry[0]])
);

/**
 * The attribute needsVerfication to trigger EVS workflow
 */
export const NEEDS_VERIFICATION_ATTRIBUTE = 'needsVerification';

/**
 * The attribute userMergeConsented to trigger Learner Merge Consent 3P workflow
 */
export const USER_MERGE_CONSENT_REQUIRED = 'userMergeConsentRequired';

/**
 * API error message when user email is not verified.
 * Used for 1P consent
 */
export const EMAIL_NOT_VERIFIED = 'EMAIL_NOT_VERIFIED';
