import { defineMessages } from 'react-intl';

export const accountlinkfaqPageDetails = defineMessages({
  learnMoreAboutProfileLinking: {
    id: 'accountlinkfaqPageDetails.learnMoreAboutProfileLinking',
    defaultMessage: 'Learn more about profile linking',
    description: 'Learn more about profile linking',
  },
  learnMoreLabel: {
    id: 'accountlinkfaqPageDetails.learnMoreLabel',
    defaultMessage: 'Learn more',
    description: 'Learn more',
  },
});
