import TextContent from '@amzn/awsui-components-react/polaris/text-content';
import * as awsui from '@amzn/awsui-design-tokens/polaris';
import { ReactNode } from 'react';
import { useIntl } from 'react-intl';
import { SHORTBREAD_BUTTON_ID } from '../constants/cookies';

import footerMessages from '../i18n/footer.messages';
import { getShortbread } from '../services/cookies';
import styles from './Footer.module.css';
import React from 'react';

type FooterProps = {
  enableCookieComponent?: boolean;
};

const footerStyle = {
  backgroundColor: awsui.colorBackgroundLayoutMain,
};

const whatYearIsIt = new Date().getFullYear();

const PipeSpacer = () => (
  <span className={styles.pipeSpacer} aria-hidden="true">
    |
  </span>
);

const FooterItem = ({ children }: { children: ReactNode }) => (
  <span>{children}</span>
);

const CookiePreferencesButton = ({ children }: { children: ReactNode }) => {
  const handleCustomizeCookies = () => {
    const shortbread = getShortbread();
    shortbread.customizeCookies();
  };
  return (
    <a
      id={SHORTBREAD_BUTTON_ID}
      href="#cookiePreferences"
      role="button"
      onClick={(event) => {
        event.preventDefault();
        handleCustomizeCookies();
      }}
      onKeyDown={(event) => {
        if (event.key === ' ') event.preventDefault();
      }}
      onKeyUp={(event) => {
        if (event.key === ' ') {
          event.preventDefault();
          handleCustomizeCookies();
        }
      }}
    >
      {children}
    </a>
  );
};
const Footer = (props: FooterProps) => {
  const cookieComponent = () => {
    if (props.enableCookieComponent === true) {
      return (
        <>
          <PipeSpacer />
          <li>
            {' '}
            <FooterItem>
              <CookiePreferencesButton>
                {formatMessage(footerMessages.cookiePreferences, {
                  i: (str) => <i>{str}</i>,
                })}
              </CookiePreferencesButton>
            </FooterItem>
          </li>
        </>
      );
    }
    return undefined;
  };
  const { formatMessage } = useIntl();

  const privacyLink = (
    <FooterItem>
      <a
        href="https://aws.amazon.com/privacy/"
        target="_blank"
        rel="noopener noreferrer"
      >
        {formatMessage(footerMessages.privacyLink)}
      </a>
    </FooterItem>
  );

  const siteTermsLink = (
    <FooterItem>
      <a
        href="https://aws.amazon.com/terms/"
        target="_blank"
        rel="noopener noreferrer"
      >
        {formatMessage(footerMessages.siteTermsLink)}
      </a>
    </FooterItem>
  );

  const copyrightInformation = (
    <FooterItem>
      {formatMessage(footerMessages.copyrightInformation, {
        year: whatYearIsIt,
      })}
    </FooterItem>
  );

  return (
    <TextContent>
      <footer className={styles.footer} style={footerStyle} role="contentinfo">
        <ul className={styles.horizontal_list}>
          <li>{privacyLink}</li>
          <PipeSpacer />
          <li>{siteTermsLink}</li>
          {cookieComponent()}
          <PipeSpacer />
          <li>{copyrightInformation}</li>
        </ul>
      </footer>
    </TextContent>
  );
};

export default Footer;
