import { defineMessages } from 'react-intl';

// These help messages are created in pairs, question + answer, they have
// a shared key ID which ends with either `:question` or `:answer`.
//
// Example:
//
// 'helpID:question': {
//   id: 'helpContent.helpID.question',
//   defaultMessage: 'Who is awesome?',
// },
// 'helpID:answer': {
//   id: 'helpContent.helpID.answer',
//   defaultMessage: 'You are awesome.',
// }

export const faqMessages = defineMessages({
  'whatIsLWA:question': {
    id: 'helpContent.whatIsLWA.question',
    defaultMessage: 'What is Login with Amazon?',
  },
  'whatIsLWA:answer': {
    id: 'helpContent.whatIsLWA.answer',
    defaultMessage:
      'Login with Amazon lets you use your Amazon.com retail sign-in email address and password to sign in to AWS Training and Certification.',
  },
  'internetBrowserSupport:question': {
    id: 'helpContent.internetBrowserSupport.question',
    defaultMessage: 'Which internet browsers do the sign-in methods support?',
  },
  'internetBrowserSupport:answer': {
    id: 'helpContent.internetBrowserSupport.answer',
    defaultMessage: `The AWS Training and Certification experience works best on:
      <ul>
        <li>Google Chrome (2 most recent major versions)</li>
        <li>Mozilla Firefox (2 most recent major versions)</li>
        <li>Microsoft Edge: (2 most recent major versions)</li>
        <li>Safari (2 most recent major versions)</li>
      </ul>`,
  },
  'signInWithAWS:question': {
    id: 'helpContent.signInWithAWS.question',
    defaultMessage:
      'Can I sign in with my AWS Management Console sign-in information?',
  },
  'signInWithAWS:answer': {
    id: 'helpContent.signInWithAWS.answer',
    defaultMessage:
      'No, AWS Management Console and AWS Training and Certification are separate portals and accounts. Your sign-in information works only for the portal you created it on.',
  },
  'reusePreviousSignInMethod:question': {
    id: 'helpContent.reusePreviousSignInMethod.question',
    defaultMessage:
      "I've signed in to other AWS Training and Certification experiences in the past. Can I use the same sign-in identity?",
  },
  'reusePreviousSignInMethod:answer': {
    id: 'helpContent.reusePreviousSignInMethod.answer',
    defaultMessage:
      'Yes, your previous sign-in identity will work for that same method.',
  },
  'arePasswordsStored:question': {
    id: 'helpContent.arePasswordsStored.question',
    defaultMessage: 'Will you store my sign-in password?',
  },
  'arePasswordsStored:answer': {
    id: 'helpContent.arePasswordsStored.answer',
    defaultMessage:
      "No. AWS Training and Certification doesn't have visibility into our users' sign-in information. Our identity management uses open auth standards to integrate with sign-in providers.",
  },
});

export const troubleshootingMessages = defineMessages({
  'preferredMethodUnavailable:question': {
    id: 'helpContent.preferredMethodUnavailable.question',
    defaultMessage: "I can't sign in with my preferred method.",
  },
  'preferredMethodUnavailable:answer': {
    id: 'helpContent.preferredMethodUnavailable.answer',
    defaultMessage: `A few things to do:
      <ol>
        <li>Make sure you're signing in with the same email address you used to create your account.</li>
        <li>Restart your email app, browser, and your computer.</li>
        <li>Choose a different sign-in method.</li>
      </ol>`,
  },
  'cannotFindHelp:question': {
    id: 'helpContent.cannotFindHelp.question',
    defaultMessage: "I can't find help for my sign-in issue.",
  },
  'cannotFindHelp:answer': {
    id: 'helpContent.cannotFindHelp.answer',
    defaultMessage:
      'If none of the the questions above fit your sign-in issue, please contact <supportLink>AWS Training and Certification customer service</supportLink>.',
  },
  'dontHaveAccess:question': {
    id: 'helpContent.dontHaveAccess.question',
    defaultMessage: "I signed in, but the page says I don't have access.",
  },
  'dontHaveAccess:answer': {
    id: 'helpContent.dontHaveAccess.answer',
    defaultMessage:
      "Contact your learning administrator to authorize your email address, which will give you access. When your learning administrator confirms that they've given you access, return to this site and try signing in again.",
  },
  'resetPassword:question': {
    id: 'helpContent.resetPassword.question',
    defaultMessage: 'I need to reset my password.',
  },
  'resetPassword:answer': {
    id: 'helpContent.resetPassword.answer',
    defaultMessage:
      "Please contact your identity's provider. AWS Training and Certification doesn't have visibility into our users' sign-in information because our identity management uses open auth standards to integrate with sign-in providers.",
  },
});
