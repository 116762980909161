import { defineMessages } from 'react-intl';

import { IdPs } from '../constants/providers';

/**
 * The provider key correlates to a IDP.
 */
export const providerMessages = defineMessages({
  [IdPs.OTP]: {
    id: 'provider.OTP',
    defaultMessage: 'One-time email passcode',
    description: 'Button label for the identity provider.',
  },
  [IdPs.AWSBuilderID]: {
    id: 'provider.AWSBuilderID',
    defaultMessage: 'AWS Builder ID',
    description: 'Button label for the identity provider.',
  },
  [IdPs.AWSSignIn]: {
    id: 'provider.AWSSignIn',
    defaultMessage: 'AWS',
    description: 'Button label for the identity provider.',
  },
  [IdPs.LoginWithAmazon]: {
    id: 'provider.LoginWithAmazon',
    defaultMessage: 'Login with Amazon',
    description: 'Button label for the identity provider.',
  },
  [IdPs.AmazonFederate]: {
    id: 'provider.AmazonFederate',
    defaultMessage: 'Amazon employee single sign-on',
    description: 'Button label for the identity provider.',
  },
  [IdPs.AmazonSystemFederate]: {
    id: 'provider.AmazonSystemFederate',
    defaultMessage: 'Amazon system account',
    description: ' ',
  },
  [IdPs.ADFS]: {
    id: 'provider.ADFS',
    defaultMessage: 'ADFS',
    description: 'Button label for the identity provider.',
  },
  [IdPs.JPMC]: {
    id: 'provider.JPMC',
    defaultMessage: 'JPMC',
    description: 'Button label for the identity provider.',
  },
  [IdPs.ScaleTestIdp]: {
    id: 'provider.ScaleTestIdp',
    defaultMessage: 'Scale Test IDP',
    description: 'Button label for the identity provider.',
  },
  [IdPs.Accenture]: {
    id: 'provider.Accenture',
    defaultMessage: 'Accenture employee',
    description: 'Button label for the identity provider.',
  },
  [IdPs.AccentureUAT]: {
    id: 'provider.AccentureUAT',
    defaultMessage: 'AccentureUAT employee',
    description: 'Button label for the identity provider.',
  },
  [IdPs.BestBuy]: {
    id: 'provider.BestBuy',
    defaultMessage: 'BestBuy employee',
    description: 'Button label for the identity provider.',
  },
  [IdPs.Westpac]: {
    id: 'provider.Westpac',
    defaultMessage: 'Westpac employee',
    description: 'Button label for the identity provider.',
  },
  [IdPs.Sophos]: {
    id: 'provider.Sophos',
    defaultMessage: 'Sophos employee',
    description: 'Button label for the identity provider.',
  },
  [IdPs.Shell]: {
    id: 'provider.Shell',
    defaultMessage: 'Shell employee',
    description: 'Button label for the identity provider.',
  },
  [IdPs.Jefferies]: {
    id: 'provider.Jefferies',
    defaultMessage: 'Jefferies employee',
    description: 'Button label for the identity provider.',
  },
  [IdPs.Curtin]: {
    id: 'provider.Curtin',
    defaultMessage: 'Curtin employee',
    description: 'Button label for the identity provider.',
  },
  [IdPs.Principal]: {
    id: 'provider.Principal',
    defaultMessage: 'Principal employee',
    description: 'Button label for the identity provider.',
  },
  [IdPs.AWSPartnerNetwork]: {
    id: 'provider.AWSPartnerNetwork',
    defaultMessage: 'AWS Partner Network',
    description: 'Button label for the identity provider.',
  },
  [IdPs.testProvider]: {
    id: 'provider.testProvider',
    defaultMessage: 'testProvider',
    description: 'Button label for the identity provider.',
  },
  [IdPs.GandalfSession]: {
    id: 'provider.GandalfSession',
    defaultMessage: '',
    description: 'Not a visible provider',
  },
  // Translation string placeholder to simplify adding new ESSO providers.
  GenericESSO: {
    id: 'provider.GenericESSO',
    defaultMessage: '{provider} employee',
    description:
      'Button label for an generic ESSO identity provider. Provider is usually the company name.',
  },
});

export const providerNameDiscriptions = defineMessages({
  [IdPs.OTP]: {
    id: 'providerDescription.OTP',
    defaultMessage: 'Use a passcode sent to the email address you specify',
    description: 'Description for OTP provider',
  },
  [IdPs.AWSBuilderID]: {
    id: 'providerDescription.AWSBuilderID',
    defaultMessage: `Use your AWS Builder ID or create one {br} <builderIdHelp>Learn more</builderIdHelp>`,
    description: 'Description for AWSBuilderID',
  },
  [IdPs.LoginWithAmazon]: {
    id: 'proiderDescription.LoginWithAmazon',
    defaultMessage: 'Use your Amazon account',
    description: 'Description for LoginWithAmazon',
  },
  [IdPs.AmazonFederate]: {
    id: 'providerDescription.AmazonFederate',
    defaultMessage: 'Use your Amazon work credentials',
    description: 'Description for AmazonFederate',
  },
  [IdPs.AWSSignIn]: {
    id: 'providerDescription.AWSSignIn',
    defaultMessage: ' ',
    description: 'Button label for the identity providerDescription',
  },
  [IdPs.AmazonSystemFederate]: {
    id: 'providerDescription.AmazonSystemFederate',
    defaultMessage: ' ',
    description: ' ',
  },
  [IdPs.ADFS]: {
    id: 'providerDescription.ADFS',
    defaultMessage: ' ',
    description: 'Button label for the identity providerDescription.',
  },
  [IdPs.JPMC]: {
    id: 'providerDescription.JPMC',
    defaultMessage: ' ',
    description: 'Button label for the identity providerDescription.',
  },
  [IdPs.ScaleTestIdp]: {
    id: 'providerDescription.ScaleTestIdp',
    defaultMessage: ' ',
    description: 'Button label for the identity providerDescription.',
  },
  [IdPs.Accenture]: {
    id: 'providerDescription.Accenture',
    defaultMessage: ' ',
    description: 'Button label for the identity providerDescription.',
  },
  [IdPs.AccentureUAT]: {
    id: 'providerDescription.AccentureUAT',
    defaultMessage: ' ',
    description: 'Button label for the identity providerDescription.',
  },
  [IdPs.BestBuy]: {
    id: 'providerDescription.BestBuy',
    defaultMessage: ' ',
    description: 'Button label for the identity providerDescription.',
  },
  [IdPs.Westpac]: {
    id: 'providerDescription.Westpac',
    defaultMessage: ' ',
    description: 'Button label for the identity providerDescription.',
  },
  [IdPs.Sophos]: {
    id: 'providerDescription.Sophos',
    defaultMessage: ' ',
    description: 'Button label for the identity providerDescription.',
  },
  [IdPs.Shell]: {
    id: 'providerDescription.Shell',
    defaultMessage: ' ',
    description: 'Button label for the identity providerDescription.',
  },
  [IdPs.Jefferies]: {
    id: 'providerDescription.Jefferies',
    defaultMessage: ' ',
    description: 'Button label for the identity providerDescription.',
  },
  [IdPs.Curtin]: {
    id: 'providerDescription.Curtin',
    defaultMessage: ' ',
    description: 'Button label for the identity providerDescription.',
  },
  [IdPs.Principal]: {
    id: 'providerDescription.Principal',
    defaultMessage: ' ',
    description: 'Button label for the identity providerDescription.',
  },
  [IdPs.testProvider]: {
    id: 'providerDescription.testproviderDescription',
    defaultMessage: ' ',
    description: 'Button label for the identity providerDescription.',
  },
  [IdPs.GandalfSession]: {
    id: 'providerDescription.gandalfSessionDescription',
    defaultMessage: ' ',
    description: 'Button label for the identity providerDescription.',
  },
  // Translation string placeholder to simplify adding new ESSO providers.
  GenericESSO: {
    id: 'providerDescription.GenericESSO',
    defaultMessage: ' ',
    description:
      'Button label for an generic ESSO identity providerDescription. providerDescription is usually the company name.',
  },
  [IdPs.AWSPartnerNetwork]: {
    id: 'providerDescription.AWSPartnerNetwork',
    defaultMessage: `Use your AWS Partner Central account {br} <apnHelp>Learn more</apnHelp>`,
    description: 'Button label for the identity providerDescription',
  },
});
