import { useEffect } from 'react';
import { SHORTBREAD_CONTAINER_ID } from '../constants/cookies';

import { useLocaleContext } from '../contexts/LocaleContextProvider';
import { createShortbreadInstance, getShortbread } from '../services/cookies';
import { AppConfig } from '../types/app';

const CookieConsent = ({ config }: { config?: AppConfig }) => {
  const { locale } = useLocaleContext();
  const enableCookieComponent = Boolean(
    config?.enableCookieComponent === 'true'
  );

  if (enableCookieComponent === false) {
    return <></>;
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    // Initialize shortbread on load and reinitialize whenever the locale changes.
    createShortbreadInstance(locale);
    const shortbread = getShortbread();
    shortbread.checkForCookieConsent();
  }, [locale]);

  return <div id={SHORTBREAD_CONTAINER_ID} />;
};

export default CookieConsent;
