import { defineMessages } from 'react-intl';

export const accountLinkWarning = defineMessages({
  containerHeader: {
    id: 'accountLinkWarning.containerHeader',
    defaultMessage: 'Did you mean to sign in to an existing profile?',
    description: 'Container header for account linking warning screen',
  },
  infoMessageSingular: {
    id: 'accountLinkWarning.infoMessageSingular',
    defaultMessage: `<p>There's an existing AWS Training & Certification profile with {email} using a different sign-in method.</p><p>To access the training activity and data tied to your existing profile, sign in using that method instead.</p>`,
    description:
      'Info message to show if only 1 existing account was found. The <p>...</p> represents 2 lines of text, email will be the email referenced',
  },
  infoMessagePlural: {
    id: 'accountLinkWarning.infoMessagePlural',
    defaultMessage: `<p>There are existing AWS Training & Certification profiles with {email} using different sign-in methods.</p><p>To access the training activity and data tied to one of your existing profiles, sign in using that method instead.</p>`,
    description:
      'Info message to show if only 1 existing account was found. The <p>...</p> represents 2 lines of text, email will be the email referenced',
  },
  accountSelectionOption: {
    id: 'accountLinkWarning.messageSelectionOption',
    defaultMessage: '{provider}, last sign in on {date}',
    description: 'Selection option for account',
  },
  accountSelectionOptionDescription: {
    id: 'accountLinkWarning.accountSelectionOptionDescription',
    defaultMessage: 'Access to existing activity and data',
    description:
      'Describes what selecting that account action will give the user',
  },
  currentSignInMethodOption: {
    id: 'accountLinkWarning.currentSignInMethodOption',
    defaultMessage: 'Continue with {provider}',
    description: 'Current sign in option selection',
  },
  currentSignInMethodOptionDescription: {
    id: 'accountLinkWarning.currentSignInMethodOption',
    defaultMessage: 'New profile with no existing activity or data',
    description:
      'Describes what selecting the current signed in account will give the user',
  },
  recommendedBadge: {
    id: 'accountLinkWarning.reccomendedBadge',
    defaultMessage: 'Recommended',
    description:
      'Badge text which will be placed next to the recommended option',
  },
  continueButton: {
    id: 'accountLinkWarning.continueButton',
    defaultMessage: 'Continue',
    description: 'Button which tells the user to continue with signin',
  },
  chooseSignin: {
    id: 'accountLinkWarning.chooseSignin',
    defaultMessage: 'Choose from the following sign-in methods:',
    description: 'Message that appears above the sign in option selection.',
  },
});

export default accountLinkWarning;
