class MissingEmailError extends Error {
  static code: string = 'MissingEmailError';
  code: string;

  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype);
    this.code = MissingEmailError.code;
  }
}

export default MissingEmailError;
