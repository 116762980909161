import {
  FORBIDDEN_FAILED_STATUS,
  FORBIDDEN_MSG_SUFFIX,
  FORBIDDEN_NO_EMAIL_DIST,
} from '../constants/auth';

export const emailExistsInForbiddenError = (errorMsg: string): boolean => {
  return (
    errorMsg.indexOf(FORBIDDEN_MSG_SUFFIX) - FORBIDDEN_FAILED_STATUS.length >
    FORBIDDEN_NO_EMAIL_DIST
  );
};
