import { defineMessages } from 'react-intl';

const emailLookupMessages = defineMessages({
  pageTitle: {
    id: 'emailLookup.pageTitle',
    defaultMessage: 'Organization',
    description: 'The page title for the organization email lookup page.',
  },
  header: {
    id: 'emailLookup.header',
    defaultMessage: 'Let’s find your organization',
    description: 'The header for the organization email lookup page.',
  },
  backButton: {
    id: 'emailLookup.backButton',
    defaultMessage: 'Back',
    description: 'Action to go back to the previous page.',
  },
  submitButton: {
    id: 'emailLookup.submitButton',
    defaultMessage: 'Continue',
    description: 'Action to submit the search form and continue.',
  },
  emailInputLabel: {
    id: 'emailLookup.emailInputLabel',
    defaultMessage: 'Enter your company email',
    description: 'Label for email input.',
  },
  invalidEmailValidation: {
    id: 'emailLookup.invalidEmailValidation',
    defaultMessage:
      'Invalid email format. Enter a valid email address and try again.',
    description: 'Validation message for an invalid email.',
  },
  organizationNotFoundInfo: {
    id: 'emailLookup.organizationNotFoundInfo',
    defaultMessage:
      'Your organization isn’t set up for single sign-on. Choose a different sign-in method to continue.',
    description:
      'Information message when the email domain could not be mapped to a organization.',
  },
  lookupRequestError: {
    id: 'emailLookup.lookupRequestError',
    defaultMessage: 'Oh no, something went wrong. Please try again.',
    description: 'When the email lookup API request fails.',
  },
});

export default emailLookupMessages;
