import Auth from '@aws-amplify/auth';
import { AppConfig } from '../types/app';
import { IdProvider } from '../types/auth';
import configService from '../services/config';

// Enable to get more transparency into Amplify Auth when debugging locally:
// (window as any).LOG_LEVEL = 'DEBUG';

let dataMemory: { [key: string]: string } = {};

// Store credentials in memory so that tokens are not kept between different sign in sessions.
export class MemoryAuthStorage {
  static setItem(key: string, value: string) {
    dataMemory[key] = value;
    return dataMemory[key];
  }

  static getItem(key: string) {
    return Object.prototype.hasOwnProperty.call(dataMemory, key)
      ? dataMemory[key]
      : undefined;
  }

  static removeItem(key: string) {
    return delete dataMemory[key];
  }

  static clear() {
    dataMemory = {};
    return dataMemory;
  }

  static sync() {
    return Promise.resolve();
  }
}

export function getAmplifyConfig(config: AppConfig) {
  const redirectUrl = `${window.location.origin}/login/response`;

  return {
    Auth: {
      storage: MemoryAuthStorage,
      region: config.region,
      userPoolId: config.authUserPoolId,
      userPoolWebClientId: config.authClientId,
      oauth: {
        domain: config.gandalfDomain,
        scope: ['openid'],
        responseType: 'code',
        redirectSignIn: redirectUrl,
        redirectSignOut: redirectUrl,
      },
    },
  };
}

export function setAmplifyConfig(config: AppConfig, idp?: IdProvider) {
  const amplifyConfig = getAmplifyConfig(config);

  // if the IDP uses Session IDP, it may be defined in another User Pool than the main Gandalf one; update the
  // configuration accordingly
  if (idp?.userPoolId && idp?.clientId && !configService.isHcUATSignIn()) {
    amplifyConfig.Auth.oauth.domain = new URL(idp.url).hostname;
    amplifyConfig.Auth.userPoolId = idp.userPoolId;
    amplifyConfig.Auth.userPoolWebClientId = idp.clientId;
  }

  Auth.configure(amplifyConfig.Auth);
}
