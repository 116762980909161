import { CONSENT_PATH, EMAIL_NOT_VERIFIED } from '../constants/api';
import { UPDATE_CONSENT } from '../constants/metrics';
import { GandalfUser } from '../types/auth';
import { request, joinUrlPath } from '../utils/apiHelper';
import startEmailVerification from './startEmailVerification';
import { NonceGenerator } from '../contexts/NonceGeneratorContextProvider';
import { AppConfig } from '../types/app';

export interface Consent {
  consentStatus: ConsentStatus;
}

export enum ConsentStatus {
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}

export async function postConsent(
  gandalfProfileEndpoint: string,
  consentGranted: boolean,
  user: GandalfUser,
  nonceGenerator: NonceGenerator,
  params: URLSearchParams,
  config: AppConfig
) {
  if (!user.jwtToken) {
    throw new Error('User token not defined');
  }

  const consent: Consent = {
    consentStatus: consentGranted
      ? ConsentStatus.ACCEPTED
      : ConsentStatus.REJECTED,
  };
  const opts: RequestInit = {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: user.jwtToken,
    },
    body: JSON.stringify({ consent }),
  };

  try {
    const result = await request(
      joinUrlPath(gandalfProfileEndpoint, CONSENT_PATH),
      opts,
      {
        metricNs: UPDATE_CONSENT,
        treat400AsOk: true,
      }
    );

    const apiResponse = await result.json();

    if (apiResponse?.message?.error === EMAIL_NOT_VERIFIED) {
      // Start Email Verification flow
      startEmailVerification(nonceGenerator, params, user, config);
    }
  } catch (error) {
    throw error; // Re-throw the error if you want it to be handled upstream
  }
}
