import { defineMessages } from 'react-intl';

export const lwaDeprecationMessages = defineMessages({
  lwaDeprecationBanneraHeader: {
    id: 'lwaDeprecationBannerHeader.text',
    defaultMessage: `As of {date}, Login with Amazon is no longer available for use to sign in to AWS Training and Certification offerings`,
    description: 'Banner header for the LWA deprecation',
  },
  lwaDeprecationDate: {
    id: 'lwaDeprecationDate.text',
    defaultMessage: 'January 31, 2024',
    description: 'Date string for LWA deprecation',
  },
  lwaDeprecationLearnMoreButton: {
    id: 'lwaDeprecationLearnMoreButton.label',
    defaultMessage: 'Learn more',
    description: 'Learn More Button in the Banner',
  },
  lwaDeprecationBannerContent: {
    id: 'lwaDeprecationBannerContent.text',
    defaultMessage: `If you previously used Login with Amazon to sign in, use your AWS Builder ID (or create one). You’ll be prompted to link your AWS Builder ID to your existing profile if they share the same email.`,
    description: 'LWA deprecation banner content',
  },
  builderIdProviderDescription: {
    id: 'builderIdProviderDescription.text',
    defaultMessage: `Use your AWS Builder ID or create one.`,
    description: 'Builder ID provider description',
  },
  builderIdProviderNote: {
    id: 'builderIdProviderNote.text',
    defaultMessage: `<b>Note:</b> If you previously used <b>Login with Amazon</b>, use this. You’ll be prompted to link your AWS Builder ID to your existing profile if they share the same email.`,
    description: 'Builder ID description note',
  },
  builderIdLearnMoreSupportLink: {
    id: 'builderIdSupportLink.text',
    defaultMessage: `<supportLink>Learn more</supportLink>`,
    description: 'Builder Id Learn More Link',
  },
});
