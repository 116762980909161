import { defineMessages } from 'react-intl';

const footerMessages = defineMessages({
  privacyLink: {
    id: 'footer.privacyLink',
    defaultMessage: 'Privacy',
    description: 'Link to privacy information.',
  },
  siteTermsLink: {
    id: 'footer.siteTermsLink',
    defaultMessage: 'Site terms',
    description: 'Link to site terms information.',
  },
  cookieInfo: {
    id: 'footer.cookieInfo',
    defaultMessage:
      'This site uses essential cookies. See our <cookieLink>Cookie Notice</cookieLink> for more information.',
    description: 'Cookie information.',
  },
  cookiePreferences: {
    id: 'footer.cookiePreferences',
    defaultMessage: 'Cookie preferences',
    description: 'Cookie preferences.',
  },
  copyrightInformation: {
    id: 'footer.copyrightInformation',
    defaultMessage: `© {year}, Amazon Web Services, Inc. or its affiliates. All rights reserved.`,
    description: 'Site copyright information for the current year.',
  },
});

export default footerMessages;
