import { defineMessages } from 'react-intl';

const challengeMessages = defineMessages({
  header: {
    id: 'challenge.header',
    defaultMessage: 'One-time email passcode',
    description: 'Main heading for Answer Challenge page.',
  },
  description: {
    id: 'challenge.description',
    defaultMessage:
      'We sent a passcode to {userEmail}. You should receive it within 5 minutes.',
    description: 'Main description for Answer Challenge page.',
  },
  passcodeInputName: {
    id: 'challenge.passcodeInputName',
    defaultMessage: 'Passcode (9-digit)',
    description: 'Name for email form field.',
  },
  resendPasscodeLabel: {
    id: 'challenge.resendPasscodeLabel',
    defaultMessage: 'Resend passcode',
    description: 'Name for resend passcode link.',
  },
  errorMessage: {
    id: 'challenge.errorMessage',
    defaultMessage: 'Incorrect passcode for this email',
    description: 'Error displayed when answer challenge fails.',
  },
  resendMessage: {
    id: 'challenge.resendMessage',
    defaultMessage: 'New passcode sent to {email}',
    description: 'Message displayed when passcode was resent.',
  },
  successMessage: {
    id: 'challenge.successMessage',
    defaultMessage: 'Signin successful. Redirecting...',
    description: 'Message displayed when passcode was successful.',
  },
  previousButtonLabel: {
    id: 'challenge.previousButtonLabel',
    defaultMessage: 'Back',
    description: 'Button label to go to Main page.',
  },
  buttonLabel: {
    id: 'challenge.buttonLabel',
    defaultMessage: 'Sign in',
    description: 'Button label for Answer Challenge.',
  },
});

export default challengeMessages;
