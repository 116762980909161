import React from 'react';

import useProviders from '../hooks/useProviders';
import Error from '../pages/Error';
import { AppConfig } from '../types/app';
import { IdProvider } from '../types/auth';

const WithProviders = ({
  children,
  config,
}: {
  children: ({ providers }: { providers: IdProvider[] }) => React.ReactNode;
  config: AppConfig;
}) => {
  let { providers, isLoading, error } = useProviders(config);

  if (isLoading) return null;

  if (error) {
    return (
      <Error config={config} details={'Could not load identity providers'} />
    );
  }

  if (!providers || !providers.length) {
    return (
      <Error
        config={config}
        details={'No providers has been enabled for the app client.'}
      />
    );
  }

  return <>{children({ providers })}</>;
};

export default WithProviders;
