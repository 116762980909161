import { Registry } from '@amzn/shortbread/dist/shortbread';

/** The element the Shortbread component is mounted into. */
export const SHORTBREAD_CONTAINER_ID = 'at2signin-shortbread-container';

/** The element ID for the cookie preferences button. */
export const SHORTBREAD_BUTTON_ID = 'at2signin-shortbread-button';

/** List of domains under which the Shortbread cookie will be stored. */
export const PROD_DOMAINS = ['.skillbuilder.aws'];

/** This ID is what Shortbread uses internally. */
export const SHORTBREAD_ELEMENT_ID = 'awsccc-sb-ux-c';

export const RUM_USER = 'cwr_u';
export const RUM_SESSION = 'cwr_s';
export const RUM_CREDENTIALS = 'cwr_c';

export const COOKIE_REGISTRY: Registry = {
  [RUM_USER]: { category: 'functional' },
  [RUM_SESSION]: { category: 'functional' },
  [RUM_CREDENTIALS]: { category: 'essential' },
};
