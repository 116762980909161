import styles from './ScreenReaderHidden.module.css';

/**
 * Visually hides the text but makes it available to screen readers.
 */
const ScreenReaderHidden = ({ children }: { children: React.ReactNode }) => (
  <span className={styles.hide}>{children} </span>
);

export default ScreenReaderHidden;
