import { AppConfig } from '../types/app';
import usePageTitle from '../utils/usePageTitle';
import challengeMessages from '../i18n/answerChallenge.messages';

import { AnswerChallengeForm } from '../components/AnswerChallengeForm';
import ContainerLayout from '../components/ContainerLayout';
import Footer from '../components/Footer';
import TopHeader from '../components/TopHeader';
import Main from '../components/Main';
import HelpLink from '../components/HelpLink';

function AnswerChallenge({ config }: { config: AppConfig }) {
  if (config.enableOtp === 'false' || !config.enableOtp) {
    throw new Error('Invalid path');
  }
  usePageTitle('Verify one-time email passcode');

  const enableCookieComponent = Boolean(
    config?.enableCookieComponent === 'true'
  );

  return (
    <>
      <TopHeader config={config} />
      <Main config={config}>
        <ContainerLayout>
          <AnswerChallengeForm
            config={config}
            headerMsg={challengeMessages.header}
            descriptionMsg={challengeMessages.description}
            showBackButton={true}
          />
          <HelpLink />
        </ContainerLayout>
      </Main>
      <Footer enableCookieComponent={enableCookieComponent} />
    </>
  );
}

export default AnswerChallenge;
