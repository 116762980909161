import { defineMessages } from 'react-intl';

const newHelpMessages = defineMessages({
  pageTitle: {
    id: 'newHelp.pageTitle',
    defaultMessage: 'Sign-in help',
    description: 'The page title of the help page, appears in the browser tab.',
  },
  headerTitle: {
    id: 'help.headerTitle',
    defaultMessage: 'What can we help you with?',
    description: 'Main header of the help page.',
  },
  sectionSingingIn: {
    id: 'newHelp.sectionSingingIn',
    defaultMessage: 'Signing in',
    description: 'Signing in  section header of the help page.',
  },
  sectionAccountLinking: {
    id: 'newHelp.sectionAccountLinking',
    defaultMessage: 'Linking a new sign-in method to your profile',
    description: 'Account linking section header of the help page.',
  },
  sectionMergingHistory: {
    id: 'newHelp.sectionMergingHistory',
    defaultMessage: 'Merging history from multiple profiles',
    description: 'Merging history section header of the help page.',
  },
});

export default newHelpMessages;
