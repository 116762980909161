import TopNavigation from '@amzn/awsui-components-react/polaris/top-navigation';
import aws_logo from '../assets/aws_logo.svg';
import { Locale, LOCALES, TextDirection } from '../constants/locales';
import { useLocaleContext } from '../contexts/LocaleContextProvider';
import metricsService from '../services/metrics';
import { useIntl } from 'react-intl';
import topNavigationMessages from '../i18n/TopNavigation.messages';
import React from 'react';

const isLtrLocale = (locale: Locale) => locale.dir === TextDirection.LTR;

const TopNavigationBar = (): JSX.Element => {
  const { locale, setLocale } = useLocaleContext();
  const { formatMessage } = useIntl();
  const localeItems = [
    ...Object.keys(LOCALES)
      .map((key) => LOCALES[key])
      .filter(isLtrLocale)
      .map((l) => ({
        text: l.label,
        id: `header-localeItem-${l.id}`,
        disabled: locale === l.id,
        lang: l.lang,
        href: '#',
      })),
  ];

  const handleLocaleChange = (event: { detail: { id: any } }) => {
    const selectedLocale = event.detail.id.replace('header-localeItem-', '');
    setLocale(selectedLocale);
    const publisher = metricsService.getPublisher('LocaleMenu');
    publisher.publishCounterMonitor('ClickLocale', 1);
    publisher.publishStringTruncate('SelectedLocale', selectedLocale);
  };

  return (
    <TopNavigation
      data-testid="top-navigation"
      identity={{
        href: '#',
        title: formatMessage(topNavigationMessages.title).toString(),
        logo: {
          src: aws_logo,
          alt: 'Logo',
        },
      }}
      utilities={[
        {
          type: 'menu-dropdown',
          text: LOCALES[locale].label,
          ariaLabel: 'Locales',
          onItemClick: handleLocaleChange,
          items: localeItems,
        },
      ]}
    />
  );
};

export default TopNavigationBar;
