import Header from '@amzn/awsui-components-react/polaris/header';
import Container from '@amzn/awsui-components-react/polaris/container';
import { Box } from '@amzn/awsui-components-react';

import styles from './CardContainer.module.css';

const CardContainer = ({
  children,
  header,
  description,
  ...rest
}: {
  children: React.ReactNode;
  header?: React.ReactNode;
  description?: string;
}) => (
  <Container {...rest}>
    <>
      <Box>
        {header ? (
          <>
            <Header
              className={styles.header}
              variant="h3"
              headingTagOverride="h1"
              description={description}
            >
              {header}
            </Header>
          </>
        ) : (
          <></>
        )}
      </Box>
      {children}
    </>
  </Container>
);

export default CardContainer;
