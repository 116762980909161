import { defineMessages } from 'react-intl';

const lwaSunsetPhaseMessages = defineMessages({
  chooseAnotherMethod: {
    id: 'lwaSunset.chooseAnotherMethod',
    defaultMessage: 'Choose another sign-in method',
    description: 'Heading for Lwa sign-in warning',
  },
  suggestBuilderIDSignInContent: {
    id: 'lwaSunset.suggestBuilderIDSignInContnet',
    defaultMessage: `<b>Login with Amazon will be unavailable after {date}. Existing customers must sign in with AWS Builder ID before then</b> (or another method, if applicable) to have an uninterrupted experience. Create an AWS Builder ID using the same email as your Login with Amazon account to link your existing profile.`,
  },
  chooseAnotherMethodButton: {
    id: 'lwaSunset.chooseAnotherMethodButton',
    defaultMessage: 'Choose another method',
    description: 'Button to go to Sign In Page',
  },
  signInWithBuilderIDButton: {
    id: 'lwaSunset.signInWithBuilderID',
    defaultMessage: 'Sign in with AWS Builder ID',
    description: 'Button for AWS Builder ID',
  },
  lwaSunsetBanner: {
    id: 'lwaSunset.banner',
    defaultMessage: `Login with Amazon will no longer be available for use to sign in to AWS Training and Certification offerings after {date}`,
    description: 'Banner message for lwa Sunset phase',
  },
  lwaSunsetBannerDescription: {
    id: 'lwaSunset.bannerDescription',
    defaultMessage: `If you previously signed in with Login with Amazon, you can link your existing profile to your AWS Builder ID if it uses the same email. <supportLink><b>Learn more about linking</b></supportLink>`,
    description: 'Banner description message for lwa Sunset phase',
  },
  lwaIdpDescription: {
    id: 'lwaSunset.lwaIdpDescription',
    defaultMessage: `<b>This method is not supported for new customers. If you’re a new customer, use AWS Builder ID instead.</b> If you’re an existing customer, create an AWS Builder ID using the same email to link your existing profile.`,
    description: 'Idp description for LWA Sunset phase',
  },
  unavailableBadge: {
    id: 'lwaSunset.unavailableBadge',
    defaultMessage: `Unavailable after {date}`,
    description: 'Badge that Lwa will be unavailable',
  },
  recommendSignInWithBuilderIdHeader: {
    id: 'lwaSunset.recommendSignInWithAmazon.header',
    defaultMessage: 'We recommend signing in with AWS Builder ID',
    description: 'Header to recommend Builder ID',
  },
  lwaSunsetContinueButton: {
    id: 'lwaSunset.ContinueButton',
    defaultMessage: 'Continue',
    description: 'Continue button for Lwa Sunset flow',
  },
  lwaSunsetPostAuthContent: {
    id: 'lwaSunset.postAuth.content',
    defaultMessage:
      'Login with Amazon is no longer supported for new customers. Sign in using AWS Builder ID or another method, if applicable.',
    description: 'Post Auth content description for LWA Sunsent flow',
  },
  continueWithLwaButton: {
    id: 'lwaSunset.preAuth.button',
    defaultMessage: 'Sign in with Login with Amazon',
    description: 'Button label to sign in with Lwa',
  },
});

export default lwaSunsetPhaseMessages;
