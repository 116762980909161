import logger from '../utils/logger';

const createStorageAdapter = (storage: Storage) => {
  return {
    setItem(key: string, value: string) {
      try {
        storage.setItem(key, value);
      } catch (error) {
        logger.debug('setItem failed for key:', key, error);
      }
    },

    getItem(key: string) {
      try {
        return storage.getItem(key);
      } catch (error) {
        logger.debug('getItem failed for key:', key, error);
      }
      return null;
    },

    removeItem(key: string) {
      try {
        storage.removeItem(key);
      } catch (error) {
        logger.debug('removeItem failed for key:', key, error);
      }
    },

    clear() {
      try {
        storage.clear();
      } catch (error) {
        logger.debug('clear failed', error);
      }
    },

    /**
     * Checks if the storage mechanism is avalable/usable.
     * To be used if local/session storage is critical for normal functionality.
     *
     * @example
     * if(localStorageAdapter.isStorageAvailable()) {
     *   localStorage.set('critical', ...);
     * } else {
     *   // notify user
     * }
     *
     * @see https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#feature-detecting_localstorage
     */
    isAvailable() {
      try {
        const testKey = '__storage_test_key__';
        storage.setItem(testKey, testKey);
        storage.removeItem(testKey);
        return true;
      } catch (error) {
        return (
          error instanceof DOMException &&
          // Everything except Firefox:
          (error.code === 22 ||
            // Firefox:
            error.code === 1014 ||
            // Test name field too, because code might not be present.
            // Everything except Firefox:
            error.name === 'QuotaExceededError' ||
            // Firefox:
            error.name === 'NS_ERROR_DOM_QUOTA_REACHED') &&
          // Acknowledge QuotaExceededError only if there's something already stored.
          storage &&
          storage.length !== 0
        );
      }
    },
  };
};

/**
 * Runtime-safe wrapper for localStorage. Storage operations might throw in
 * certain client configurations, for example if 3rd party cookies are disabled.
 */
export const localStorageAdapter = createStorageAdapter(localStorage);

/**
 * Runtime-safe wrapper for sessionStorage. Storage operations might throw in
 * certain client configurations, for example if 3rd party cookies are disabled.
 */
export const sessionStorageAdapter = createStorageAdapter(sessionStorage);
