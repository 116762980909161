import { useNavigate } from 'react-router-dom';
import { AppURL } from '../constants/urls';
import { useEffect } from 'react';
import { REQUIRE_EMAIL_VERIFICATION } from '../constants/auth';

export function SignInVerified() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate(
      `${AppURL.Login}${window.location.search}&${REQUIRE_EMAIL_VERIFICATION}=true`,
      { replace: true }
    );
  }, [navigate]);

  return <></>;
}
