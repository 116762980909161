import ContainerLayout from '../components/ContainerLayout';
import Footer from '../components/Footer';
import Main from '../components/Main';
import TopHeader from '../components/TopHeader';
import { AppConfig } from '../types/app';
import { useEffect, useState } from 'react';
import { getAmplifyConfig } from '../components/postAuth/ValidateAccountLink';
import { AuthToken, unmarshalAuthToken } from '../utils/authToken';

import { LinkAccounts } from '../components/LinkAccounts';
import useGandalfPostAuth, {
  accountLinkingInit,
} from '../hooks/useGandalfPostAuth';
import Auth from '@aws-amplify/auth';

import MissingEmail from '../components/MissingEmail';
import SignInFailed from '../components/SignInFailed';
import SignInUnauthorized from '../components/SignInUnauthorized';
import MissingEmailError from '../errors/MissingEmailError';
import UnauthorizedError from '../errors/UnauthorizedError';

import providersService from '../services/providers';
import { ExclusivityType } from '../types/auth';
import jwtDecode from 'jwt-decode';

const lastUsedToken = 'LAST_USED_TOKEN';

function PostAccountLinkValidate({ config }: { config: AppConfig }) {
  const [validationAuthToken, setValidationAuthToken] = useState<string>();
  const [unmarshalledAuthToken, setUnmarshalledAuthToken] =
    useState<AuthToken | null>();
  const [isExclusive, setIsExclusive] = useState<boolean | null>();
  const [authNonce, setAuthNonce] = useState<string | null>();

  const marshalledGandalfAuthToken = localStorage.getItem(lastUsedToken);

  const amplifyConfig = getAmplifyConfig(config);
  Auth.configure(amplifyConfig.Auth);

  accountLinkingInit(true, config);

  // eslint-disable-next-line
  const { user, error, customState, receivedNonce } = useGandalfPostAuth(
    true,
    config
  );

  const provider = providersService.transformNameToIdProvider(
    user?.providerName ?? '',
    config.gandalfDomain
  );

  useEffect(() => {
    if (user && user.jwtToken) {
      setValidationAuthToken(user?.jwtToken);
    }
    if (receivedNonce) {
      setAuthNonce(receivedNonce);
    }

    if (marshalledGandalfAuthToken && authNonce) {
      const originalAuthToken = unmarshalAuthToken(
        marshalledGandalfAuthToken,
        receivedNonce
      );
      if (originalAuthToken) {
        const unmarshalledAuthdDecode: any = jwtDecode(
          originalAuthToken.jwtToken
        );
        const originalAuthAssociateTo = JSON.parse(
          unmarshalledAuthdDecode['associate_to']
        );
        setIsExclusive(
          originalAuthAssociateTo.Exclusivity === ExclusivityType.EXCLUSIVE
        );
      }
      setUnmarshalledAuthToken(originalAuthToken);
    }
  }, [user, receivedNonce, marshalledGandalfAuthToken, authNonce]);
  if (error) {
    if (error instanceof UnauthorizedError) {
      return (
        <SignInUnauthorized config={config} error={error} provider={provider} />
      );
    } else if (error instanceof MissingEmailError) {
      return <MissingEmail config={config} error={error} provider={provider} />;
    }
    return <SignInFailed error={error} config={config} provider={provider} />;
  }

  return (
    <>
      <TopHeader config={config} />
      <Main config={config}>
        <ContainerLayout>
          {unmarshalledAuthToken && validationAuthToken ? (
            <LinkAccounts
              config={config}
              at2Token={unmarshalledAuthToken}
              validationToken={validationAuthToken}
              isExclusive={isExclusive}
            />
          ) : (
            <></>
          )}
        </ContainerLayout>
      </Main>
      <Footer />
    </>
  );
}

export default PostAccountLinkValidate;
