import { Header } from '@amzn/awsui-components-react';
import React from 'react';
import { IdPs } from '../constants/providers';
import { providerMessages } from '../i18n/provider.messages';
import signinMessages from '../i18n/signin.messages';
import { ExclusivityType, GandalfUser } from '../types/auth';
import { useIntl } from 'react-intl';
import styles from './CardContainer.module.css';
import SignInFailed from './SignInFailed';
import providersService from '../services/providers';
import { AppConfig } from '../types/app';
import accountlinkConsent from '../i18n/accountlinkConsent.messages';

const SingleProfileAccountLinkHeader = ({
  user,
  config,
}: {
  user: GandalfUser;
  config: AppConfig;
}): JSX.Element => {
  const { formatMessage } = useIntl();
  const provider = providersService.transformNameToIdProvider(
    user.providerName,
    config.gandalfDomain
  );
  if (user.associate_to) {
    return (
      <Header className={styles.header} variant="h2" headingTagOverride="h1">
        {user.associate_to.Exclusivity === ExclusivityType.INCLUSIVE
          ? formatMessage(
              accountlinkConsent.confirmExistingProfileOwnershipToLinkHeader,
              {
                currentProvider:
                  providerMessages[user.associate_to.LinkedToIDP as IdPs]
                    ?.defaultMessage ||
                  formatMessage(signinMessages.organizationSigninButton),
              }
            )
          : formatMessage(
              accountlinkConsent.confirmExistingProfileOwnershipToTransferHeader,
              {
                currentProvider:
                  providerMessages[user.associate_to.LinkedToIDP as IdPs]
                    ?.defaultMessage ||
                  formatMessage(signinMessages.organizationSigninButton),
              }
            )}
      </Header>
    );
  } else {
    return (
      <SignInFailed error={new Error('SignIn Failed')} provider={provider} />
    );
  }
};

export default SingleProfileAccountLinkHeader;
